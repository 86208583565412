'strict mode';

export default {
  turbolinksLoad() {
    const elem = document.getElementById('careerNav');
    if (elem !== null) {
      $('body').scrollspy({ target: '#careerNav', offset: 134 });
      const navLinks = document.querySelectorAll('#careerNav .nav-link');
      navLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
          const clickLink = e.target;
          if (clickLink.getAttribute('href').indexOf('#') === 0) {
            e.preventDefault();
            const targetElem = document.querySelector(clickLink.getAttribute('href'));
            $('body').scrollTo(targetElem, 400, { offset: { top: -134 } });
          }
        });
      });
    }
  },
};
