'strict mode';

import clipboard from 'clipboard-polyfill';

function replaceFacebookButtonHref(tourURL) {
  const facebookBtn = $('.btn-share-facebook');
  const facebookHref = facebookBtn.attr('href');
  const facebookHrefReplaced = facebookHref
    .replace(/href=(.*?)&/, `href=${tourURL}&`)
    .replace(/redirect_uri=(.*?)$/, `redirect_uri=${tourURL}`);
  facebookBtn.attr('href', facebookHrefReplaced);
}

function replaceMessengerButtonHref(tourURL) {
  const messengerBtn = $('.btn-share-messenger');
  const messengerHref = messengerBtn.attr('href');
  const messengerHrefReplaced = messengerHref
    .replace(/link=(.*?)&/, `link=${tourURL}&`)
    .replace(/redirect_uri=(.*?)$/, `redirect_uri=${tourURL}`);
  messengerBtn.attr('href', messengerHrefReplaced);
}

function sharingContent(tourRoute, tourURL) {
  return `ทัวร์${tourRoute} ดูข้อมูลเพิ่มเติมได้ที่ ${tourURL}`;
}

function replaceLineButtonHref(tourRoute, tourURL) {
  const text = sharingContent(tourRoute, tourURL);
  $('.btn-share-line').attr('href', `line://msg/text/?${text}`);
}

function replaceEmailButtonHref(tourRoute, tourURL) {
  $('.btn-share-email').attr('href', `mailto:?subject=ทัวร์${tourRoute}&` +
  `body=${sharingContent(tourRoute, tourURL)}`);
}

function copyTourURL() {
  $('.btn-tour-copy').on('click', (e) => {
    e.preventDefault();
    const tourURL = $('.textfield-tour-url').val();
    clipboard.writeText(tourURL);
    $(e.target).text('คัดลอกแล้ว');
  });

  $('.tour-links').on('click', () => {
    $('.btn-tour-copy').text('คัดลอก');
  });
}

function replaceSharingButtons(tourRoute, tourURL) {
  replaceFacebookButtonHref(tourURL);
  replaceMessengerButtonHref(tourURL);
  replaceLineButtonHref(tourRoute, tourURL);
  replaceEmailButtonHref(tourRoute, tourURL);
}

export default {
  turbolinksLoad() {
    const shareModal = $('.modal-sharing');
    if (shareModal.length > 0) {
      shareModal.on('show.bs.modal', function (event) {
        const $link = $(event.relatedTarget);
        const tourRoute = $link.data('tour-route');
        const tourURL = $link.data('tour-url');
        $(this).find('.textfield-tour-url').val(tourURL);
        replaceSharingButtons(tourRoute, tourURL);
      });

      copyTourURL();
    }
  },
};
