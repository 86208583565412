/* eslint max-len: 0 */

'strict mode';

/*
 *
 * grouped function
 *
 */

function sendSearchTrackingToGA(e) {
  const elem = e.target;
  const isMobile = elem.classList.contains('js-mobile-searchBox');
  const isSearchBoxOpen = isMobile ? !elem.classList.contains('opened') :
    !elem.closest('.search-tooltip').querySelector('input').checked;
  if (isSearchBoxOpen) {
    ga('send', 'event', 'UX', 'open', 'search_box');
  } else {
    ga('send', 'event', 'UX', 'close', 'search_box');
  }
}

export default {
  turbolinksLoad() {
  // filter:click

  /*
   *
   * navbar: contact
   *
   */

    $('.contact-popup').on('click', () => ga('send', 'event', 'contact', 'open', 'contact_popup'));
    $('#contact_popup_phone').on('click', () => ga('send', 'event', 'contact', 'click', 'contact_popup_phone'));
    $('#contact_popup_line').on('click', () => ga('send', 'event', 'contact', 'click', 'contact_popup_line'));
    $('#contact_popup_email').on('click', () => ga('send', 'event', 'contact', 'click', 'contact_popup_email'));

    /*
     *
     * tour: booking channel
     *
     */

    $('#booking_phone_popup_large, #booking_phone_popup_small').on('click', () => ga('send', 'event', 'Lead', 'open', 'call'));
    $('#booking_phone_popup_confirm').on('click', () => ga('send', 'event', 'Lead', 'confirm', 'call-confirm'));

    $('#booking_line_popup_large, #booking_line_popup_small').on('click', () => ga('send', 'event', 'Lead', 'open', 'line'));
    $('#booking_line_popup_confirm').on('click', () => ga('send', 'event', 'Lead', 'confirm', 'line-confirm'));

    /*
     *
     * tour: pdf
     *
     */

    $('#pdf_download_daily').on('click', () => ga('send', 'event', 'Lead', 'click', 'pdf_download_daily'));

    /*
     *
     * tour: order form
     *
     */

    $('#booking_tour_large, #booking_tour_small').on('click', () => ga('send', 'event', 'Lead', 'click', 'book_tour'));
    $('.call-to-action__action-reserve').on('click', () => ga('send', 'event', 'Lead', 'click', 'book_tour_bar'));
    $('.period-row-header').find('.btn-outline-orange:not(.collapsed)').on('click', () => ga('send', 'event', 'Lead', 'click', 'book_tour_open'));
    $('.period-row-table').find('.btn-orange').on('click', () => ga('send', 'event', 'Lead', 'click', 'open_order_form'));
    $('#new_order').on('submit', () => ga('send', 'event', 'Lead', 'submit', 'book_tour_form'));

    /*
     *
     * tour: expand days
     *
     */

    $('.expand-days').on('click', () => ga('send', 'event', 'Tour', 'click', 'expand-all-days'));

    /*
     *
     * tour: navigation
     *
     */

    $('#tour-nav').find('.nav-link').on('click', function () {
      const $this = $(this);
      const target = $this.data('target').replace('#', '');
      ga('send', 'event', 'Tour', 'click', `nav_link_${target}`);
    });

    /*
     *
     * tour: related tours
     *
     */

    $('#related').find('.tour-card-vertical').on('click', function () {
      const $this = $(this);
      ga('send', 'event', 'Lead', 'click', 'related', $this.data('tour-id'));
    });

    /*
     *
     * tour card
     *
     */

    $("[id^='tour-links-add-bookmark']").on('click', function () {
      const $this = $(this);
      ga('send', 'event', 'tour-card', 'click', 'bookmark', $this.attr('data-tour-id'));
    });

    $("[id^='tour-links-share']").on('click', function () {
      const $this = $(this);
      ga('send', 'event', 'tour-card', 'click', 'open_share_modal', $this.attr('data-tour-id'));
    });

    /*
     *
     * search: filter / sort
     *
     */

    $('.result__header .js-modal-filters-button').on('click', () => {
      ga('send', 'event', 'Lead', 'click', 'filter-modal');
    });

    $('.result__header .dropdown-toggle').on('click', () => {
      ga('send', 'event', 'Lead', 'click', 'sort');
    });

    /*
     *
     * card on click
     *
     */
    $('.tour-card, .tour-card-vertical').on('click', (e) => {
      const cardClasses = '.tour-card, .tour-card-vertical';
      const card = $(e.target).hasClass(cardClasses) ? $(e.target) : $(e.target).parents(cardClasses);
      const id = card.data('tour-id');
      const category = card.find("[class*='-route']:first").text().trim();
      const collection = card.find('.tour-card-title').text().trim();
      const title = `#${id} ${collection} ${category}`;
      const hasPrice = card.find('.tour-card-price') != null;
      const price = hasPrice ? card.find('.tour-card-price').text().replace(/\D/g, '') : 0;
      const list = document.body.dataset.ecPage;
      ga('ec:addProduct', {
        id: id,
        name: title,
        price: price,
        brand: card.data('brand'),
        category: category,
        position: card.data('position'),
      });
      ga('ec:setAction', 'click', {
        list: list,
      });
    });

    /*
     *
     * tabbar items
     *
     */
    if (document.querySelector('.call-to-action') !== null) {
      document.querySelector('.call-to-action .call-to-action__action-call').addEventListener('click', () => {
        ga('send', 'event', 'lead', 'open', 'call_tabbar');
      });

      document.querySelector('.call-to-action .call-to-action__action-line').addEventListener('click', () => {
        ga('send', 'event', 'lead', 'open', 'line_tabbar');
      });

      document.querySelector('.call-to-action .call-to-action__action-reserve').addEventListener('click', () => {
        ga('send', 'event', 'lead', 'click', 'book_tour_tabbar');
      });
    }

    /*
     *
     * nav search
     *
     */
    if (document.querySelector("[for='searchBox']") !== null) {
      document.querySelector("[for='searchBox']").addEventListener('click', sendSearchTrackingToGA);
    }
  },
  /*
   *
   * exported function for using only bind-nav search submit event
   *
   */
  sendUseSearchTrackingToGA(elem) {
    elem.addEventListener('submit', function (e) {
      ga('send', 'event', 'UX', 'use', 'search_box');
    });
  },
};
