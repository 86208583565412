import algoliasearch from '../modules/algoliasearch';
import DeviceOption from '../modules/deviceOption';

const collectionList = {
  get: {
    continent: () => document.getElementById('continent'),
    formClasses: () => {
      const option = new DeviceOption();
      let classes = [];
      if (option.isMobileDevice) {
        classes = ['absolute', 'mobile'];
      } else {
        classes = ['absolute'];
      }
      return classes;
    },
  },
  open: () => {
    const option = new DeviceOption();
    const continent = collectionList.get.continent();
    const formClasses = collectionList.get.formClasses();
    const body = document.querySelector('body');
    const backdrop = document.createElement('div');
    const form = document.querySelector('.page-splash__form');
    backdrop.classList.add('modal-backdrop', 'fade', 'search-backdrop');
    body.classList.add('modal-open');
    body.appendChild(backdrop);
    document.querySelector('.menu-toggler').classList.remove('fa-bars');
    document.querySelector('.menu-toggler').classList.add('fa-times');
    setTimeout(() => {
      backdrop.classList.add('show');
      continent.classList.remove('d-none');
      formClasses.forEach((className) => {
        form.classList.add(className);
      });
      if (option.isMobileDevice) {
        form.dataset.offset = `${form.offsetTop}px`;
        form.style.top = `${form.offsetTop}px`;
        setTimeout(() => {
          form.style.top = '30px';
        }, 100);
      }
      form.previousElementSibling.classList.add('push');
      form.querySelector('.input-group').classList.add('focused');
    }, 100);
  },
  close: () => {
    const continent = collectionList.get.continent();
    const formClasses = collectionList.get.formClasses();
    const body = document.querySelector('body');
    const backdrop = body.querySelector('.modal-backdrop.fade.show.search-backdrop');
    const form = document.querySelector('.page-splash__form');
    continent.classList.add('d-none');
    body.classList.remove('modal-open');
    if (backdrop !== null) backdrop.classList.remove('show');
    form.style.top = form.dataset.offset;
    document.querySelector('.menu-toggler').classList.remove('fa-times');
    document.querySelector('.menu-toggler').classList.add('fa-bars');
    setTimeout(() => {
      if (backdrop !== null) body.removeChild(backdrop);
      formClasses.forEach((className) => {
        form.classList.remove(className);
      });
      form.removeAttribute('style');
      form.removeAttribute('data-offset');
      form.previousElementSibling.classList.remove('push');
      form.querySelector('.input-group').classList.remove('focused', 'filled', 'selected');
    }, 300);
  },
};

const homeSearchInput = {
  init: (elem) => {
    elem.addEventListener('focus', homeSearchInput.bindBodyClickEvent);
    if (elem.classList.contains('aa-input')) elem.addEventListener('input', homeSearchInput.bindInputEvent);
    homeSearchInput.bindSubmitForm(elem);
  },
  bindSubmitForm: (elem) => {
    elem.closest('.input-group').querySelector('.input-group-append').addEventListener('click', () => {
      const input = document.getElementById('homeSearchQuery');
      if (input.value !== '') elem.closest('form').submit();
    });
  },
  bindInputEvent: (e) => {
    const elem = e.target;
    const inputGroup = elem.closest('.input-group');
    const isEmpty = elem.value.length !== 0;
    const backDrop = document.querySelector('.search-backdrop');
    homeSearchInput.bindInputCloseEvent(elem);
    if (isEmpty) {
      inputGroup.classList.add('filled');
      backDrop.classList.add('filled');
    } else {
      inputGroup.classList.remove('filled');
      backDrop.classList.remove('filled');
    }
  },
  bindInputCloseEvent: (input) => {
    let elem;
    const isAlreadyExisted = document.querySelector('.algolia-autocomplete .close') !== null;
    if (isAlreadyExisted) {
      elem = document.querySelector('.algolia-autocomplete .close');
    } else {
      const closeBtnElem = document.createElement('span');
      closeBtnElem.classList.add('close', 'fas', 'fa-times-circle');
      closeBtnElem.addEventListener('click', () => {
        const queryInput = document.getElementById('homeSearchQuery');
        queryInput.value = '';
        queryInput.trigger('click');
        queryInput.closest('.input-group').classList.remove('filled');
      });
      input.parentNode.insertBefore(closeBtnElem, input.nextSibling);
    }
    return elem;
  },
  bindBodyClickEvent: () => {
    document.querySelector('body').addEventListener('click', homeSearchInput.checkClickElement);
  },
  isTargetIsClickAble: (elem) => {
    const option = new DeviceOption();
    const isClickable = elem.closest('#continent') !== null ||
                      elem.getAttribute('id') === 'homeSearchQuery' ||
                      elem.closest('.algolia-autocomplete');
    return option.isMobileDevice ? isClickable || elem.closest('.modal-backdrop') !== null : isClickable;
  },
  checkClickElement: (event) => {
    const elem = event.target;
    const isTargetIsClickAble = homeSearchInput.isTargetIsClickAble(elem);
    const isAlreadyClosed = document.querySelector('.modal-backdrop.fade.show') === null;
    if (isTargetIsClickAble) {
      if (isAlreadyClosed) collectionList.open();
    } else {
      collectionList.close();
    }
  },
};

export default {
  turbolinksLoad() {
    if (document.getElementById('homeSearchQuery') !== null) {
      const input = document.getElementById('homeSearchQuery');
      algoliasearch.attachSearchEngine(input);
      homeSearchInput.init(input);

      document.querySelector('.page-splash__form').addEventListener('submit', (e) => {
        if (input.value === '') e.preventDefault();
      });
    }
  },
  turbolinksBeforeCache() {
    if (document.getElementById('homeSearchQuery') !== null) {
      algoliasearch.destroy();
    }
  },
};
