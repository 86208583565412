import is from 'is_js';

export default {
  redirect(url) {
    if (is.undefined(url)) return;
    if ((url.pathname.indexOf('/users/home') === 0) && is.desktop()) {
      url.pathname = '/users/edit';
      window.location.replace(url);
    }
  },
};
