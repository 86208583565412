'strict mode';


function fetchFaceBookShare() {
  const currentUrl = window.location.href;
  fetch(`https://graph.facebook.com/${currentUrl}`)
  .then(function(response) {
    return response.json();
  }).then(function(data) {
    const element = document.querySelector(".blog-content__post-shared .blog-content__post-details__title");
    element.innerHTML = data["share"].length ? data["share"]["share_count"] : 0;
  })
}

export default {
  turbolinksLoad() {
    if(document.querySelector(".blog_posts.index") !== null) {
      $("#blogs-highlighted-desktop").owlCarousel({
        nav: true,
        slideSpeed : 300,
        items: 1,
        center: true,
        dotsEach: true,
      });
      $("#blogs-highlighted-mobile").owlCarousel({
        slideSpeed : 300,
        items: 1,
        center: true,
        dotsEach: true,
        margin: 24,
      });
    }

    if(document.querySelector(".blog_posts.show") !== null && document.querySelector(".blog_posts.lists.show") === null) {
      fetchFaceBookShare();
      $('.js-related-tour').carousel()
    }
  },
  turbolinksBeforeCache() {
    if(document.querySelector(".blog_posts.index") !== null) {
      $("#blogs-highlighted-desktop").owlCarousel('destroy')
      $("#blogs-highlighted-mobile").owlCarousel('destroy')
    }
  }
};

