'strict mode';

/* eslint class-methods-use-this: 0 */

class OwlCarouselApp {
  constructor() {
    this.elementsTemp = [];
    this.options = {
      mouseDrag: false,
      touchDrag: true,
      margin: 10,
      stagePadding: 0,
      dots: true,
      items: 1,
      dotsEach: 1,
      slideBy: 1,
      loop: false,
    };
  }

  styleSetValue($element, key, value) {
    if (value) {
      $element.css(key, value);
    }
  }

  setup($element, countItems) {
    if (countItems <= 1) {
      this.styleSetValue($element, 'margin', $element.data('margin-no-arrow'));
    }
  }

  watchNavigation($element) {
    const $firstItem = $element.find('.owl-item:first-child');
    const $lastItem = $element.find('.owl-item:last-child');
    const $navLeft = $element.find('.owl-box-left');
    const $navRight = $element.find('.owl-box-right');
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.attributeName === 'class') {
          if ($firstItem.hasClass('active')) {
            $navLeft.addClass('owl-disabled-nav');
            $navRight.removeClass('owl-disabled-nav');
          } else if ($lastItem.hasClass('active')) {
            $navRight.addClass('owl-disabled-nav');
            $navLeft.removeClass('owl-disabled-nav');
          } else {
            $navRight.removeClass('owl-disabled-nav');
            $navLeft.removeClass('owl-disabled-nav');
          }
        }
      });
    });
    observer.observe($firstItem[0], { attributes: true });
    observer.observe($lastItem[0], { attributes: true });
  }

  start() {
    const $owl = $('.owl-carousel-1-way');
    const $vm = this;
    if ($owl.length > 0) {
      $owl.each(function (index, element) {
        const $element = $(element);
        const $items = $element.html();
        const countItems = $element.children().length;
        $vm.setup($element, countItems);
        $vm.elementsTemp.push({ $element, $items });
        $vm.replaceContent($element, $items, countItems);
        const $carouselItem = $element.find('.owl-carousel');
        const $eleBoxLeft = $element.find('.owl-box-left');
        const $eleBoxRight = $element.find('.owl-box-right');
        $vm.create({
          $element,
          $carouselItem,
          index,
          $eleBoxLeft,
          $eleBoxRight,
        });
        $element.find('.owl-nav.disabled').remove();
        $carouselItem.owlCarousel('onResize');
        $carouselItem.trigger('refresh.owl.carousel');
        $carouselItem.trigger('next.owl.carousel');
        $carouselItem.trigger('prev.owl.carousel');
        $carouselItem.parents('.owl-carousel-1-way').addClass('loaded');
        index += 1;
        $vm.watchNavigation($element);
      });
    }
  }

  replaceContent($element, $items, countItems) {
    const boxLeft = countItems > 1 ? '<div class="owl-box-left owl-disabled-nav"></div>' : '';
    const boxRight = countItems > 1 ? '<div class="owl-box-right"></div>' : '';

    $element.html(`
      ${boxLeft}
      <div class="owl-box-center">
        <div class="curations-row owl-carousel owl-theme owl-height">
          ${$items}
        </div>
      </div>
      ${boxRight}
    `);
  }

  create(options) {
    if (options.$carouselItem.length > 0) {
      const optionsObject = this.options;
      options.$carouselItem.owlCarousel(optionsObject);
      this.insertOwlCarouselNav(options);
      this.bindNavEvents(
        options.$carouselItem,
        options.$eleBoxLeft.find('.btn-nav-prev-item'),
        options.$eleBoxRight.find('.btn-nav-next-item'),
      );
    }
  }

  insertOwlCarouselNav(options) {
    const owlDots = options.$carouselItem.find('.owl-dots');
    const prevItemClass = `btn-nav-prev-item-${options.index}`;
    const nextItemClass = `btn-nav-next-item-${options.index}`;
    const $prevItemElem = options.$carouselItem.find(`.${prevItemClass}`);
    const $nextItemElem = options.$carouselItem.find(`.${nextItemClass}`);
    const isExisted = $prevItemElem.length && $nextItemElem.length;
    const isDotEnable = !owlDots.hasClass('disabled');

    if (isDotEnable) {
      const btnNavPrevItem = `
        <div class="btn-nav-prev-item ${prevItemClass}">
          <i class="far fa-arrow-left"></i>
        </div>`;
      const btnNavNextItem = `
        <div class="btn-nav-next-item ${nextItemClass}">
          <i class="far fa-arrow-right"></i>
        </div>`;

      if (!isExisted) {
        $(options.$eleBoxLeft).html(btnNavPrevItem);
        $(options.$eleBoxRight).html(btnNavNextItem);
      }
      options.$carouselItem.find('.owl-dot').each((index, element) => {
        const $element = $(element);
        $element.find('span').text(index + 1);
        $element.append(' <div class="owl-dot-line"></div>');
      });
    } else {
      $prevItemElem.remove();
      $nextItemElem.remove();
    }
  }

  bindNavEvents($owlCarousel, $prev, $next) {
    $prev.on('click', () => { $owlCarousel.trigger('prev.owl.carousel'); });
    $next.on('click', () => { $owlCarousel.trigger('next.owl.carousel'); });
  }

  destroy() {
    const $owl = $('.owl-carousel-1-way');
    if ($owl.length > 0) {
      $owl.owlCarousel('destroy');
      $owl.off('resized.owl.carousel');
    }
    if (this.elementsTemp.length > 0) {
      this.elementsTemp.forEach(function (v) {
        v.$element.html(v.$items);
      });
    }
  }
}

const App = new OwlCarouselApp();

export default {
  turbolinksLoad() {
    App.start();
  },
  turbolinksBeforeCache() {
    App.destroy();
  },
};
