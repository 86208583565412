export const IPAD_PRO_WIDTH = 1024;
export const SMALL_DEVICES_WIDTH = 767.98;
export const LARGE_DEVICES_BREAKPOINT = 992;
export const ANIMATE_DURATION = 500;
export const TOUR_NAV_HEIGHT = 41;
export const MAIN_TOP_NAVBAR_HEIGHT = 58.98;

export const MOBILE_WIDTH = 320;
export const MOBILE_MEDIUM_WIDTH = 375;
export const MOBILE_LARGE_WIDTH = 576;
export const TABLET_WIDTH = 768;
export const LAPTOP_WIDTH = 1024;
export const DESKTOP_WIDTH = 1440;

export const ALGOLIA_APPLICATION_ID = '588JO4AHTX';
export const ALGOLIA_SEARCH_API_KEY = '795861b135fb54452f756389d4d935fc';
