'strict mode';

import clipboard from 'clipboard-polyfill';

function notifyCopiedMessage($elem) {
  $elem.addClass('copied');

  setTimeout(function () {
    $elem.removeClass('copied');
  }, 1600);
}

function clickToCopy() {
  $('[data-click-to-copy]').on('click', function (e) {
    e.preventDefault();
    const $elem = $(e.currentTarget);
    const textToCopy = $elem.data('click-to-copy');

    clipboard.writeText(textToCopy)
      .then(notifyCopiedMessage($elem));
  });
}

export default {
  turbolinksLoad() {
    if ($('[data-click-to-copy]').length > 0) {
      clickToCopy();
    }
  },
};
