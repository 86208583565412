'strict mode';

const revokeToken = () => {
  $.ajax({
    method: 'DELETE',
    url: '/users/notifications/line_notify',
  }).done(function(data) {
    const $lineNotifySubscriber = $('#line-notify-subscriber');

    $lineNotifySubscriber.prop('checked', false);
  });
}

export default {
  turbolinksLoad() {
    $('#line-notify-subscriber').change(function(event) {
      const subscribing = event.currentTarget.checked;

      if (subscribing) {
        setTimeout(function() {
          window.open('/users/auth/line_notify');
        }, 400);
      } else {
        revokeToken();
      }
    });
  },
};
