'strict mode';

import axios from 'axios';

function convertExperiment(event) {
  const element = event.target;

  axios.post('/field_test/conversions', {
    experiment: {
      slug: element.dataset.fieldTestExperiment,
      goal: element.dataset.fieldTestGoal || null
    }
  });
}

export default {
  turbolinksLoad() {
    const fieldTestExperiments = document.querySelectorAll('[data-field-test-experiment]');

    fieldTestExperiments.forEach((experiment) => {
      experiment.addEventListener('click', convertExperiment);
    });
  }
};
