'strict mode';

function readURL(input) {
  if (input.files && input.files[0]) {
    const reader = new FileReader();

    reader.onload = function (e) {
      $('.js-img-uploader-preview').attr('src', e.target.result)
        .removeClass('d-none')
        .next('i')
        .remove();
    };

    reader.readAsDataURL(input.files[0]);
  }
}

export default {
  turbolinksLoad() {
    $('#user_avatar').change(function () {
      readURL(this);
    });
  },
};
