'strict mode';

class QuerySelector {
  id(value) {
    return document.getElementById(value);
  }
  class(value) {
    return document.getElementsByClassName(value);
  }
  data(key, value) {
    const objectList = document.querySelectorAll(`[${key}]`);
    const result = [];
    objectList.forEach((object) => {
      if (object.getAttribute(key) === value) {
        result.push(object);
      }
    });
    return result;
  }
}

export default new QuerySelector();
