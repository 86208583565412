const signInModal = {
  bindOnSubmitFormEvent: (form) => {
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      const url = form.getAttribute('action');
      const formData = new FormData(form);
      const request = new XMLHttpRequest();
      request.open('POST', url, true);
      request.send(formData);
      request.onload = () => { signInModal.requestOnloadAction(form, request); };
    });
  },
  setInvalid: (input) => {
    input.classList.add('is-invalid');
    input.classList.remove('is-valid');
  },
  unauthenticateFeedback: {
    show: (elem, type) => {
      const unauthenticateElem = elem.querySelector(`.unauthenticate.${type}`);
      unauthenticateElem.classList.remove('d-none');
      unauthenticateElem.classList.add('d-block');

      const invalidFormatElem = elem.querySelector(`.invalid-format.${type}`);
      invalidFormatElem.classList.remove('d-block');
      invalidFormatElem.classList.add('d-none');
    },
    hide: (elem, type) => {
      const unauthenticateElem = elem.querySelector(`.unauthenticate.${type}`);
      unauthenticateElem.classList.remove('d-block');
      unauthenticateElem.classList.add('d-none');

      const invalidFormatElem = elem.querySelector(`.invalid-format.${type}`);
      invalidFormatElem.classList.remove('d-none');
      invalidFormatElem.classList.add('d-block');
    },
  },
  requestOnloadAction: (form, request) => {
    if (request.status === 200) {
      window.location.reload();
    } else {
      const emailInput = form.querySelector('#sign_in_modal_email');
      const passwordInput = form.querySelector('#sign_in_modal_password');
      const { message } = JSON.parse(request.responseText);

      switch (message) {
        case 'not_found_in_database':
          signInModal.setInvalid(emailInput);
          signInModal.unauthenticateFeedback.show(form, 'email');
          emailInput.addEventListener('input', () => {
            signInModal.unauthenticateFeedback.hide(form, 'email');
          }, { once: true });
          break;
        case 'invalid':
          signInModal.setInvalid(passwordInput);
          signInModal.unauthenticateFeedback.show(form, 'password');
          passwordInput.addEventListener('input', () => {
            signInModal.unauthenticateFeedback.hide(form, 'password');
          }, { once: true });
          break;
        default:
          break;
      }
    }
  },
  checkDisabledSubmitForm: () => {
    if ($('#sign_in_modal_email').hasClass('is-invalid')) {
      $('#submit-sign-in-modal').attr('disabled', true);
    } else {
      $('#submit-sign-in-modal').removeAttr('disabled');
    }
  },
};
export default {
  turbolinksLoad() {
    const form = document.getElementById('sign_in_modal_form');
    if (form) {
      signInModal.bindOnSubmitFormEvent(form);
      const emailInput = form.querySelector('#sign_in_modal_email');
      emailInput.addEventListener('input', () => {
        signInModal.checkDisabledSubmitForm();
      });
    }
  },
};
