import readMore from './read-more';

// function close modal (for modal opened)
function closeModal(delay) {
  const target = $('body');
  const form = $('#filters');
  const parent = form.parents('.section-filters').parent();
  if (target.is('.modal-open')) {
    target.removeClass('modal-open');
    form.find('.filter').removeClass('m-3');
    parent.removeClass('show');
    if (delay) {
      parent.delay(1000).queue(function (next) {
        $(this).removeClass('modal fade d-block bg-white mobile-filters px-0').addClass('d-none');
        next();
      });
    } else {
      parent.removeClass('modal fade d-block bg-white mobile-filters px-0').addClass('d-none');
    }
  }
}

function format(num) {
  return numeral(num).format();
}

// function initialize price slider
function initializePriceSlider(elem) {
  if (is.undefined(elem)) {
    return false;
  }
  const min = Math.floor(+$('#price-min').val() / 1000).toFixed() * 1000;
  const max = Math.ceil(+$('#price-max').val() / 1000).toFixed() * 1000;
  const from = Math.floor(+$('#price-from').val() / 1000).toFixed() * 1000;
  const to = Math.ceil(+$('#price-to').val() / 1000).toFixed() * 1000;

  return elem.ionRangeSlider({
    type: 'double',
    step: 2000,
    min: min,
    max: ((max / 2000) * 2000 > max) ? max : (max + 2000),
    from: (min === from) ? min : from,
    to: (max === to) ? max : to,
    prettify: format,
    onStart: function () {
      $('#pricing-range').prop('disabled', true);
    },
    onFinish: function (data) {
      $('#price-from').val(data.from);
      $('#price-to').val(data.to).trigger('change');
    },
  });
}

function initializeTurbolinksLoad() {
  // on filter clicked
  const filter = $('#filters, .js-search-filters');
  filter.find(':input:not(.date-range, #pricing-range)').unbind('change').on('change', function () {
    const form = $(this).parents('form');
    const target = form.parents('.section-filters').parent();
    if (target.is('.mobile-filters')) {
      $.get(`/tours/search/total?${form.serialize()}`, function (data) {
        $('.js-mobile-btn-row').find('.total').text(data);
      });
    } else {
      form.submit();
    }
  });

  $('.date-range.start').unbind('change').on('change', function () {
    $(this).datepicker('hide').parents('.input-group').next()
      .find(':input')
      .focus();
  });

  $('.date-range.end').unbind('hide').on('hide', function (e) {
    const endInput = $(e.target);
    const fromInput = endInput.parents('.input-group').prev().find(':input');
    const startDate = fromInput.val();
    const endDate = endInput.val();
    const form = endInput.parents('form');
    const target = form.parents('.section-filters').parent();
    if (startDate.length > 0 && endDate.length > 0) {
      if (target.is('.mobile-filters')) {
        // todo reduce dry code
        $.get(`/tours/search/total?${form.serialize()}`, function (data) {
          $('.js-mobile-btn-row').find('.total').text(data);
        });
      } else {
        form.submit();
      }
    } else if (endDate.length > 0 && startDate < 1) {
      fromInput.focus();
    }
  });

  // on mobile filter click
  $('.js-modal-filters-button').unbind('click').click(function () {
    // def target
    const form = $('#filters');
    const target = form.parents('.section-filters').parent();

    // prepare modal nav
    const nav = $('<div>', {
      class: 'navbar filter-nav sticky-top d-lg-none navbar-light bg-light',
    });

    nav.append($('<div>', {
      class: 'row w-100 align-items-center ml-0 no-gutters',
    }));

    ['left', 'center', 'right'].forEach(position =>
      nav.find('.row').append($('<div>', {
        class: `col text-${position}`,
      })));

    nav.find('.text-center').append($('<h4>', {
      class: 'm-0 new-thai-font',
    }).text('คัดกรอง'));
    nav.find('.text-right').append($('<button>', {
      class: 'btn btn-outline-orange close-modal',
    }).text('ปิด').bind('click', function () {
      closeModal(true);
    }));

    // showing up modal
    $('body').toggleClass('modal-open');
    form.find('.filter').toggleClass('m-3');

    // add nav to modal if didn't have
    if (target.find('.filter-nav').length === 0) {
      target.prepend(nav);
    }
    target.toggleClass('d-none');
    target.toggleClass('modal fade d-block bg-white mobile-filters px-0');
    target.delay(300).queue(function (next) {
      $(this).toggleClass('show');
      next();
    });

    // add event listener on resize screen
    $(window).on('resize', function () {
      if (window.innerWidth >= 768) {
        closeModal();
      }
    });

    // set total data
    $.get(`/tours/search/total?${form.serialize()}`, function (data) {
      $('.js-mobile-btn-row').find('.total').text(data);
    });

    // binding submit / reset btn
    target.find('.submit').click(function () {
      form.submit();
      closeModal(true);
    });
    target.find('.reset').click(function () {
      form.find(':input:checked').prop('checked', false).trigger('change');
    });
  });

  // check and initialize price slider
  const priceSlider = $('#pricing-range');
  if (priceSlider.length > 0) {
    initializePriceSlider(priceSlider);
  }

  // initialize date range picker
  const dateRange = $('.input-daterange');
  if (dateRange.length > 0) {
    dateRange.datepicker({
      language: 'th',
      clearBtn: true,
      keepEmptyValues: true,
      todayHighlight: true,
      format: 'yyyy/mm/dd',
      templates: {
        leftArrow: '&#60;',
        rightArrow: '&#62;',
      },
      maxViewMode: 1,
    });
  }
}

// document.addEventListener('turbolinks:before-cache', function (e) {
//   $('#filters, .js-search-filters').find(':input').off();
//   $('.js-modal-filters-button').off();
// });
export default {
  turbolinksBeforeCache() {
    const priceSlider = $('#pricing-range');
    if (priceSlider.length > 0) {
      priceSlider.data('ionRangeSlider').destroy();
    }
  },
  turbolinksLoad() {
    initializeTurbolinksLoad();
    if (document.body.contains(document.getElementById('search-results'))) {
      $(document)
        .ajaxStart(function () {
          Turbolinks.controller.adapter.progressBar.setValue(0);
          Turbolinks.controller.adapter.progressBar.show();
        })
        .ajaxComplete(function () {
          Turbolinks.controller.adapter.progressBar.setValue(100);
          Turbolinks.controller.adapter.progressBar.hide();
          initializeTurbolinksLoad();
          readMore.turbolinksLoad();
        })
        .ajaxError(function () {
          const notice = `<div class="result__none-block jumbotron jumbotron-fluid bg-white">
            <div class="container">
              <div class="row no-gutters">
                <div class="col-12 text-center">
                  <p>ไม่สามารถแสดงผลได้ในขณะนี้<br/>กรุณาลองใหม่อีกครั้ง</p>
                </div>
              </div>
            </div>
          </div>`;
          $('#search-results').html(notice);
          Turbolinks.controller.adapter.progressBar.setValue(100);
          Turbolinks.controller.adapter.progressBar.hide();
        });
    }
  },
};
