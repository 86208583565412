'strict mode';

function bindScrollEvent($button) {
  $(window).on('scroll', () => {
    if ($(window).scrollTop() > $(window).height() / 2) {
      $button.css('display', 'flex');
    } else {
      $button.css('display', 'none');
    }
  });
}

function unbindScrollEventBeforeCache() {
  $(document).on('turbolinks:before-cache', function () {
    $(window).off('scroll');
  });
}

function goToTopOnClick($button) {
  $button.on('click', (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  });
}

export default function goToTopButtonInitializer($button) {
  bindScrollEvent($button);
  unbindScrollEventBeforeCache();
  goToTopOnClick($button);
}

