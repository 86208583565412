'strict mode';

export default {
  turbolinksLoad() {
    $('.video-selector').find('.thumbnail').click(function () {
      const channel = $(this).data('target');
      const container = $('.video-container');
      const selector = $('.video-selector');
      container.find('.video').addClass('d-none');
      container.find(`#${channel}`).removeClass('d-none');
      selector.find('.thumbnail').removeClass('active');
      $(this).addClass('active');
    });
  },
};
