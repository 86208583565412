'strict mode';

/* eslint class-methods-use-this: 0 */

import { LARGE_DEVICES_BREAKPOINT } from '../../shared/constants';

class OwlCarouselSwiper {
  constructor() {
    this.elementsTemp = [];
    this.windowWidth = window.innerWidth;
    this.breakPoint = LARGE_DEVICES_BREAKPOINT;
    this.options = {
      mouseDrag: false,
      touchDrag: true,
      margin: 10,
      stagePadding: 0,
      dots: false,
      dotsEach: 1,
      responsive: {
        0: {
          items: 2,
          slideBy: 2,
        },
        992: {
          items: 3,
          slideBy: 3,
        },
      },
    };
    this.optionTourCard = {
      mouseDrag: false,
      touchDrag: true,
      margin: 10,
      stagePadding: 0,
      dots: false,
      dotsEach: 1,
      responsive: {
        0: {
          items: 1,
          slideBy: 1,
        },
        992: {
          items: 3,
          slideBy: 3,
        },
      },
    };
  }

  start() {
    const $owl = $('[data-component-name="swiper"]');
    const $vm = this;
    if ($owl.length > 0) {
      let index = 0;
      $owl.each(function () {
        const $element = $(this);
        const $items = $element.html();
        const countItems = $element.children().length;
        $vm.elementsTemp.push({ $element, $items });
        $vm.replaceContent($element, $items, countItems);
        const $carouselItem = $element.find('.owl-carousel');
        const $eleBoxLeft = $element.find('.carousel-prev');
        const $eleBoxRight = $element.find('.carousel-next');
        $vm.create({
          $element,
          $carouselItem,
          index,
          $eleBoxLeft,
          $eleBoxRight,
        });
        $element.find('.owl-nav.disabled').remove();
        $carouselItem.owlCarousel('onResize');
        index += 1;
      });
    }
  }

  replaceContent($element, $items, countItems) {
    const optionObject = $element.hasClass('tour-swiper') ? 'optionTourCard' : 'options';
    const maxItem = this.windowWidth < this.breakPoint ? this[`${optionObject}`].responsive['0'].items : this[`${optionObject}`].responsive[`${this.breakPoint}`].items;
    const prev = '<div class="carousel-prev disabled"></div>';
    const next = countItems > maxItem ? '<div class="carousel-next"></div>' : '<div class="carousel-next disabled"></div>';
    const page = Math.ceil(countItems / maxItem);
    $element.html(`
      <div class="carousel-viewport">
        <div class="owl-carousel owl-theme owl-height">${$items}</div>
      </div>
      <div class="carousel-nav d-flex">
        ${prev}
        <div class="pages" data-current="1"> of ${page} </div> 
        ${next}
      </div>
    `);
  }

  create(options) {
    if (options.$carouselItem.length > 0) {
      const carouselOption = options.$element.hasClass('tour-swiper') ? this.optionTourCard : this.options;
      options.$carouselItem.owlCarousel(carouselOption);
      this.insertOwlCarouselNav(options);
      this.bindNavEvents(
        options.$carouselItem,
        options.$eleBoxLeft.find('.btn-nav-prev-item'),
        options.$eleBoxRight.find('.btn-nav-next-item'),
      );
    }
  }

  insertOwlCarouselNav(options) {
    const prevItemClass = `btn-nav-prev-item-${options.index}`;
    const nextItemClass = `btn-nav-next-item-${options.index}`;
    const $prevItemElem = options.$carouselItem.find(`.${prevItemClass}`);
    const $nextItemElem = options.$carouselItem.find(`.${nextItemClass}`);
    const isExisted = $prevItemElem.length && $nextItemElem.length;
    const btnNavPrevItem = `
        <div class="btn-nav-prev-item ${prevItemClass}">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>`;
    const btnNavNextItem = `
        <div class="btn-nav-next-item ${nextItemClass}">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </div>`;
    if (!isExisted) {
      $(options.$eleBoxLeft).html(btnNavPrevItem);
      $(options.$eleBoxRight).html(btnNavNextItem);
    }
  }

  bindNavEvents($owlCarousel, $prev, $next) {
    $prev.on('click', (e) => { if (!$(e.target).parents('.carousel-prev').hasClass('disabled')) $owlCarousel.trigger('prev.owl.carousel'); });
    $next.on('click', (e) => { if (!$(e.target).parents('.carousel-next').hasClass('disabled')) $owlCarousel.trigger('next.owl.carousel'); });
    ['next', 'prev'].forEach((direction) => {
      $owlCarousel.on(`${direction}.owl.carousel`, (e) => {
        const $nav = $(e.target).parents('.carousel-viewport').next('.carousel-nav');
        const $pageEle = $nav.find('.pages');
        const maxPage = +$pageEle.html().match(/\d+/g);
        let curPage = +$pageEle.attr('data-current');

        if (direction === 'next') {
          curPage += 1;
        } else {
          curPage -= 1;
        }

        if (curPage === 1) {
          $nav.find('.carousel-prev').addClass('disabled');
          $nav.find('.carousel-next').removeClass('disabled');
        } else if (curPage === maxPage) {
          $nav.find('.carousel-next').addClass('disabled');
          $nav.find('.carousel-prev').removeClass('disabled');
        } else {
          $nav.find('.carousel-next, .carousel-prev').removeClass('disabled');
        }

        $pageEle.attr('data-current', curPage);
      });
    });

    $owlCarousel.on('resized.owl.carousel', (e) => {
      const items = e.item.count;
      const $element = $(e.target).parents('.swiper');
      const optionObject = $element.hasClass('tour-swiper') ? 'optionTourCard' : 'options';
      const maxItem = window.innerWidth < this.breakPoint ? this[`${optionObject}`].responsive['0'].items : this[`${optionObject}`].responsive[`${this.breakPoint}`].items;
      const page = Math.ceil(items / maxItem);
      $element.find('.carousel-nav .pages').attr('data-current', 1).text(` of ${page}`);
      $owlCarousel.trigger('to.owl.carousel', 1);
    });
  }

  destroy() {
    const $owl = $('.owl-carousel-3-way');
    if ($owl.length > 0) {
      $owl.each(function () {
        const $carouselItem = $(this).find('.owl-carousel');
        $($carouselItem).owlCarousel('destroy');
        ['resized', 'next', 'prev'].forEach((event) => {
          $($carouselItem).off(`${event}.owl.carousel`);
        });
      });
    }
    if (this.elementsTemp.length > 0) {
      this.elementsTemp.forEach(function (v) {
        v.$element.html(v.$items);
      });
    }
  }
}

const App = new OwlCarouselSwiper();

export default {
  turbolinksBeforeCache() {
    App.destroy();
  },
  turbolinksLoad() {
    App.start();
  },
};
