export default {
  turbolinksLoad() {
    const inputs = document.querySelectorAll('input.showable');
    if (inputs !== null) {
      inputs.forEach((input) => {
        const toggler = input.parentNode.querySelector('span');
        toggler.addEventListener('click', (e) => {
          const elem = e.target;
          const isPasswordType = input.getAttribute('type') === 'password';
          const inputType = isPasswordType ? 'text' : 'password';
          input.setAttribute('type', inputType);
          if (isPasswordType) {
            elem.classList.remove('fa-eye');
            elem.classList.add('fa-eye-slash');
          } else {
            elem.classList.remove('fa-eye-slash');
            elem.classList.add('fa-eye');
          }
        });
      });
    }
  },
};
