'strict mode';

const scrollableNav = {
  disableNext: (scrollElem) => {
    scrollElem.next("div[class$='--nav']").find("i[class*='right']").addClass('disabled');
  },
  disablePrev: (scrollElem) => {
    scrollElem.next("div[class$='--nav']").find("i[class*='left']").addClass('disabled');
  },
  enableBoth: (scrollElem) => {
    scrollElem.next("div[class$='--nav']").find('i').removeClass('disabled');
  },
};

const Scrollable = {
  check: (totalWidth, containerWidth) => totalWidth > containerWidth,
  init: (scrollElem) => {
    scrollElem.addClass('scroll-viewport').removeClass('scroll')
      .parents('#recommended')
      .addClass('scrollable');
    // attach event
    Scrollable.attachLREvent(scrollElem);
    Scrollable.attachScrollEvent(scrollElem);
  },
  attachLREvent: (scrollElem) => {
    ['left', 'right'].forEach((direction) => {
      scrollElem.next("div[class$='--nav']").find(`i[class*='${direction}']`).click(() => {
        let scrollPointer = scrollElem.scrollLeft();
        if (direction === 'left') {
          scrollPointer -= scrollElem.width();
        } else {
          scrollPointer += scrollElem.width();
        }
        scrollElem.scrollTo({ top: 0, left: scrollPointer }, 600);
      });
    });
  },
  attachScrollEvent: (scrollElem) => {
    const scrollElemNode = scrollElem[0];
    const viewPortWidth = scrollElem.width();
    const scrollElemTotalWidth = scrollElemNode.scrollWidth;
    const scrollHitRight = scrollElemTotalWidth - viewPortWidth;
    scrollElemNode.addEventListener('scroll', () => {
      if (scrollHitRight === scrollElem.scrollLeft()) {
        scrollableNav.disableNext(scrollElem);
      } else if (scrollElem.scrollLeft() > 1) {
        scrollableNav.enableBoth(scrollElem);
      } else {
        scrollableNav.disablePrev(scrollElem);
      }
    });
  },
};

function checkScrollable(totalWidth, containerWidth) {
  return totalWidth > containerWidth;
}

export default {
  turbolinksLoad() {
    const scrollElem = $('.scroll');
    let totalWidth = 0;
    let containerWidth = $('body > .wrapper > .container').width();

    if (scrollElem.find('.item').length === 0) {
      scrollElem.parents('#recommended').removeClass('mb-3');
    } else {
      scrollElem.find('.item').each(function () {
        totalWidth += Number($(this).width().toFixed());
      });

      window.addEventListener('resize', () => {
        containerWidth = $('body > .wrapper > .container').width();
        if (Scrollable.check(totalWidth, containerWidth)) {
          Scrollable.init(scrollElem);
        }
      });

      if (checkScrollable(totalWidth, containerWidth)) {
        Scrollable.init(scrollElem);
      }
    }
  },
};
