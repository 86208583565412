'strict mode';

const toggleSummaryDescription = (periodCard, buttonCollapse) => {
  const summaryDescriptions = periodCard.querySelectorAll('.period-card__summary--description');

  buttonCollapse.classList.toggle('period-card__button--active');
  summaryDescriptions.forEach((summaryDescription) => {
    summaryDescription.classList.toggle('period-card__summary--collapsed');
  });
};

const blindCollapseEvent = (periodCard) => {
  const buttonCollapses = periodCard.querySelectorAll('.period-card__button--collapse');

  buttonCollapses.forEach((buttonCollapse) => {
    buttonCollapse.addEventListener('click', (event) => {
      toggleSummaryDescription(periodCard, buttonCollapse)
    });
  });
};

export default {
  turbolinksLoad() {
    if (document.querySelectorAll('[data-component="period-card"]') !== null) {
      const periodCards = document.querySelectorAll('[data-component="period-card"]');

      periodCards.forEach(blindCollapseEvent);
    }
  }
};
