'strict mode';

const TablistInstace = {
  init: (elem) => {
    // clone elem and remove all non necessary attribute
    const cloneNav = elem.cloneNode(false);
    ['role', 'id', 'data-component'].forEach((attr) => {
      cloneNav.removeAttribute(attr);
    });
    cloneNav.classList.add('cloned');
    cloneNav.classList.remove('mb-3');

    // create tablist scroller and add cloned elem
    const tabScroller = document.createElement('div');
    tabScroller.classList.add('tablist-scroller');
    tabScroller.appendChild(cloneNav);

    // calculate total width then move tab into scroller
    let totalWidth = 0;
    elem.querySelectorAll('.nav-item').forEach((tab) => {
      totalWidth += tab.clientWidth + 5;
      tabScroller.querySelector('.cloned').appendChild(tab);
    });

    // prep elem & added tablist scroller to main elem
    tabScroller.querySelector('.cloned').style.minWidth = `${totalWidth}px`;
    elem.classList.remove('justify-content-center');
    elem.appendChild(tabScroller);

    // create tablist control & insert to elem
    const node = document.createElement('div');
    const prev = document.createElement('div');
    const next = document.createElement('div');
    node.classList.add('tablist-control');
    prev.classList.add('tablist-control-tablist-prev', 'disabled');
    next.classList.add('tablist-control-tablist-next');
    TablistInstace.bindEvent(prev, [20, totalWidth]);
    TablistInstace.bindEvent(next, [20, totalWidth]);
    node.appendChild(prev);
    node.appendChild(next);
    elem.appendChild(node);
  },
  bindEvent: (elem, limit) => {
    const move = 100;
    elem = $(elem);
    elem.on('click', (e) => {
      if ($(e.target).hasClass('disabled')) return;
      const view = $(e.target).parents('#pills-tab');
      const scroll = view.find('.cloned');
      const currentPosition = parseInt(scroll.css('left'));
      limit[1] = view.outerWidth() - scroll.outerWidth();
      if (elem.hasClass('tablist-control-tablist-prev') && currentPosition < limit[0]) {
        scroll.stop(false, true).animate({ left: `+=${move}` }, { duration: 400 });
      } else if (currentPosition >= limit[1]) {
        scroll.stop(false, true).animate({ left: `-=${move}` }, { duration: 400 });
      }
      setTimeout(() => { TablistInstace.togglePrevNextState(view, scroll.css('left'), limit); }, 400);
    });
  },
  togglePrevNextState: (view, currentPosition, limit) => {
    currentPosition = parseInt(currentPosition);
    view.find('.tablist-control-tablist-prev').removeClass('disabled');
    view.find('.tablist-control-tablist-next').removeClass('disabled');
    console.log(currentPosition, limit[0]);
    if (currentPosition >= (limit[0] - 1)) {
      view.find('.tablist-control-tablist-prev').addClass('disabled');
    } else if (currentPosition <= limit[1]) {
      view.find('.tablist-control-tablist-next').addClass('disabled');
    }
  },
};

export default {
  turbolinksLoad() {
    if (document.querySelectorAll('[data-component="tablist"]') !== null) {
      document.querySelectorAll('[data-component="tablist"]').forEach((elem) => {
        TablistInstace.init(elem);
      });
    }
  },
};
