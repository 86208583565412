'strict mode';

/*
 *
 * For css plase take a look oon collapsible.scss
 * Todo - separate out each collapsible item to make more readable
 *
 */

const searchSecNav = {
  init: function (animate) {
    if (document.querySelector('body.searches, body.collections.show, body.long_tails.show') != null) {
      const classes = is.undefined(animate) ? 'bg-white' : 'slide-in bg-white';
      $('.result__header').clone(true, true).addClass(classes).appendTo('.main-navbar')
        .addClass('with-secondary')
        .delay(300)
        .queue(function (next) {
          $(this).removeClass('slide-in').removeAttr('style');
          next();
        });
    }
  },
  destroy: function () {
    $('.main-navbar > .result__header').remove();
  },
  hide: function () {
    $('.main-navbar > .result__header').hide();
  },
  show: function () {
    $('.main-navbar > .result__header').removeAttr('style');
  },
};

export default {
  turbolinksBeforeCache() {
    if ($('.result__header').length > 0) {
      searchSecNav.destroy();
    }
  },
  turbolinksLoad() {
    if ($('.result__header').length > 0) {
      // first added class to body to applied css
      $('body').addClass('js-search-scroll-controlled');

      let position = 0;
      const safeZone = 60; // set to equal of height of navbar
      let filterRowOffset = Math.round(+$('.result__header:first-child').offset().top);

      // added resize event for set new offset when resize
      window.addEventListener('resize', () => {
        if ($('.result__header').length > 0) {
          const elem = $('.main-navbar .result__header').length ? $('.result__header:nth-child(1)') : $('.result__header');
          filterRowOffset = Math.round(+elem.offset().top);
        }
      });

      // init clone secnav if didn't have and current scrollTop is greater than elem offset
      if ($(window).scrollTop() > filterRowOffset) {
        searchSecNav.init(true);
      }

      window.addEventListener('scroll', () => {
        const scrolledPosition = $(window).scrollTop();
        const trackingTarget = $('body');
        if (scrolledPosition > position && scrolledPosition > safeZone) {
          trackingTarget.attr('data-scroll', 'down');

          if (scrolledPosition > filterRowOffset && !($('.main-navbar .result__header').length)) {
            searchSecNav.init();
          } else if (scrolledPosition > filterRowOffset) {
            searchSecNav.show();
          }
        } else {
          trackingTarget.attr('data-scroll', 'up');
          if (scrolledPosition < filterRowOffset - safeZone) {
            searchSecNav.hide();
          }
        }
        position = scrolledPosition;
      });
    }
  },
};
