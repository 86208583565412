'strict mode';

function animateScrolling(elem, target, duration, scrollTo) {
  target.animate({ scrollLeft: scrollTo }, duration, function () {
    elem.addClass('animating');
  }).promise().done(function () {
    elem.removeClass('animating');
  });
}

function getTargetElement(elem) {
  return elem.parent('div').find('.tab-content .tab-pane.active .scroll-viewport');
}

export default {
  turbolinksLoad() {
    /* eslint consistent-return:0 */

    const next = $('.js-next-item-btn');
    const prev = $('.js-prev-item-btn');
    const elementWidth = 158;
    let scrollTo = 0;

    /* improve this sections */

    next.click(function () {
      if (!next.hasClass('animating')) {
        const target = getTargetElement($(this));
        const current = target.scrollLeft();
        const remain = current % elementWidth;
        if (remain !== 0) {
          scrollTo = current + (elementWidth - remain);
        } else {
          scrollTo = current + elementWidth;
        }
        animateScrolling(prev, target, 300, scrollTo);
      }
    });

    prev.click(function () {
      if (!prev.hasClass('animating')) {
        const target = getTargetElement($(this));
        const current = target.scrollLeft();
        const remain = current % elementWidth;
        if (remain !== 0) {
          scrollTo = current - (elementWidth + remain);
        } else {
          scrollTo = current - elementWidth;
        }
        animateScrolling(prev, target, 300, scrollTo);
      }
    });
  },
};
