import is from 'is_js';

const formValidate = {
  bindOnchangeEvent: (form) => {
    form.querySelectorAll('.validation-needed').forEach((input) => {
      input.addEventListener('input', () => {
        formValidate.checkInput.byType(input);
        formValidate.enableSubmitButton(form);
      });
    });
  },
  checkInput: {
    byType: (input) => {
      const inputType = input.type;
      let isValid = false;
      switch (inputType) {
        case 'email':
          isValid = formValidate.checkInput.emailType(input);
          break;
        case 'password':
          isValid = formValidate.checkInput.passwordType(input);
          break;
        case 'text':
          isValid = formValidate.checkInput.textType(input);
          break;
        default:
          isValid = false;
      }
      formValidate.checkInput.setValidState(input, isValid);
    },
    textType: input => input.value.trim().length > 0,
    passwordType: (input) => {
      // password must contain at least character and have both alphabet and number
      const pattern = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/gm;
      return pattern.test(input.value);
    },
    emailType: input => is.email(input.value),
    setValidState: (input, isValid) => {
      if (isValid) {
        formValidate.setValid(input);
      } else {
        formValidate.setInvalid(input);
      }
    },
  },
  setValid: (input) => {
    input.classList.add('is-valid');
    input.classList.remove('is-invalid');
  },
  setInvalid: (input) => {
    input.classList.add('is-invalid');
    input.classList.remove('is-valid');
  },
  enableSubmitButton: (form) => {
    const invalidInput = form.querySelector('.validation-needed.is-invalid');
    if (!invalidInput) {
      form.querySelector('input[type="submit"]').removeAttribute('disabled');
    }
  },
};


export default {
  turbolinksLoad() {
    if (document.querySelector('.form-validation')) {
      document.querySelectorAll('.form-validation').forEach((form) => {
        formValidate.bindOnchangeEvent(form);
      });
    }
  },
};
