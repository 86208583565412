'strict mode';

/* eslint class-methods-use-this: ["error", { "exceptMethods": [
  "switchDaysCaretsToUp", "switchDaysCaretsToDown", "waitAndAnimateToOffset",
  "toggleDaysNavigationLinks"
  ]}] */

import stickybits from 'stickybits';

import {
  animateToOffset,
  waitAndAnimateToOffset,
  minusOffSetDueToTourNavHeight,
} from '../../shared/utilities';
import { ANIMATE_DURATION } from '../../shared/constants';

const DailyItinerary = (() => {
  /**
     * ------------------------------------------------------------------------
     * Constants
     * ------------------------------------------------------------------------
     */
  const TIMEOUT = 400;
  const TOP_SPACE_OF_DAY_SUMMARY = 10;

  let dayCircleExpanded = false;

  class TourDailyItinerary {
    constructor() {
      stickybits('.days-navigation');
      this.showHideAllDaysLink();
      this.hideOtherDaysAndShowSpecificDay();
      $('.days-navigation').css('top', '62px');
      this.dayOffsetTop = [];
      this.onWindowChanges();
      this.activateDayCircleOnScroll();
    }

    /**
       * ------------------------------------------------------------------------
       * Public
       * ------------------------------------------------------------------------
       */

    showHideAllDaysLink() {
      $('.expand-days').on('click', (event) => {
        event.preventDefault();
        $('.day-circle').removeClass('closed');
        $('.day-container').removeClass('d-none');
        this.switchDaysCaretsToUp($('.day-caret-toggle'));
        this.toggleDaysNavigationLinks();
      });

      $('.collapse-days').on('click', (event) => {
        event.preventDefault();
        const offset = $('#itinerary').offset().top;
        animateToOffset(minusOffSetDueToTourNavHeight(offset), ANIMATE_DURATION);
        $('.day-circle').removeClass('closed');
        $('.day-container').addClass('d-none');
        this.switchDaysCaretsToDown($('.day-caret-toggle'));
        this.toggleDaysNavigationLinks();
      });
    }

    hideOtherDaysAndShowSpecificDay() {
      $('.expandDayCircle, .day-summary').click((event) => {
        event.preventDefault();
        dayCircleExpanded = true;

        const day = $(event.currentTarget).data('day');
        const $circleDay = $(`.circle-day-${day}`);
        const $dayDetail = $(`#day_${day}`);
        const isThisBoxClose = $dayDetail.hasClass('d-none');
        const $caret = $dayDetail.prev().find('.day-caret-toggle');

        const openBox = () => {
          $circleDay.removeClass('closed');
          $dayDetail.removeClass('d-none');
          this.switchDaysCaretsToUp($caret);
          this.waitAndAnimateToOffset($dayDetail.prev());
        };

        const closeBox = () => {
          $circleDay.addClass('closed');
          $dayDetail.addClass('d-none');
          this.switchDaysCaretsToDown($caret);
          this.waitAndAnimateToOffset($('#itinerary'));
        };

        if (isThisBoxClose) {
          $('.day-circle').addClass('closed');
          $('.day-container').addClass('d-none');
          openBox();
        } else {
          closeBox();
        }
      });
    }

    activateDayCircleOnScroll() {
      $(window).on('scroll', () => {
        if ($('.expand-days').hasClass('d-none') && !dayCircleExpanded) {
          this.updateDaysOffsetTop();
          const matchedIndex = _.findLastIndex(this.dayOffsetTop, day => $(window).scrollTop() >= minusOffSetDueToTourNavHeight(day.offsetTop) - TOP_SPACE_OF_DAY_SUMMARY);
          if (matchedIndex >= 0) {
            const { day } = this.dayOffsetTop[matchedIndex];
            $('.day-circle').addClass('closed');
            $(`.circle-day-${day}`).removeClass('closed');
          }
        }
      });
    }

    onWindowChanges() {
      $(window).resize(() => {
        this.updateDaysOffsetTop();
      });
    }

    /**
       * ------------------------------------------------------------------------
       * Private
       * ------------------------------------------------------------------------
       */

    switchDaysCaretsToUp($element) {
      $element
        .removeClass('fa-caret-down')
        .addClass('fa-caret-up');
    }

    switchDaysCaretsToDown($element) {
      $element
        .removeClass('fa-caret-up')
        .addClass('fa-caret-down');
    }

    waitAndAnimateToOffset($element) {
      const offset = $element.offset().top;
      dayCircleExpanded = false;
      waitAndAnimateToOffset(
        minusOffSetDueToTourNavHeight(offset) - TOP_SPACE_OF_DAY_SUMMARY,
        ANIMATE_DURATION,
        TIMEOUT,
      );
    }

    updateDaysOffsetTop() {
      this.dayOffsetTop = [];
      $.each($('.day-summary'), (index, value) => {
        this.dayOffsetTop.push({
          day: index + 1,
          offsetTop: $(value).parent().offset().top - TOP_SPACE_OF_DAY_SUMMARY,
        });
      });
    }

    toggleDaysNavigationLinks() {
      $('.expand-days').toggleClass('d-none');
      $('.collapse-days').toggleClass('d-none');
    }
  }

  return TourDailyItinerary;
})();

export default DailyItinerary;
