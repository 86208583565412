'strict mode';

const period = {
  getCurrentPeriod: function () {
    return $("[name='order[period_id]'] :selected");
  },
  getPrices: function (option) {
    const elem = (option === undefined) ? this.getCurrentPeriod() : option;
    const prices = {};
    prices.adults_single = parseInt(elem.data('adult_double_price')) +
                           parseInt(elem.data('adult_single_increment'));
    prices.adults_double = +elem.data('adult_double_price');
    prices.children_with_bed = +elem.data('child_with_bed_price');
    prices.children_without_bed = +elem.data('child_without_bed_price');
    return prices;
  },
  getSeats: function (option) {
    const elem = (option === undefined) ? this.getCurrentPeriod() : option;
    return +elem.data('seat');
  },
};

const priceTable = {
  setNewPrices: function (prices) {
    if (prices === undefined) {
      prices = period.getPrices();
    }

    for (const type in prices) {
      const row = $(`.js-pricing-table__row-${type}`);
      const calc = prices[type] * +row.find(':input').val();
      const price = numeral(calc).format();
      row.find('.js-pricing-table__pricing-price span').text(numeral(prices[type]).format());
      row.find('.js-pricing-table__pricing-price__calculated span').text(price);
    }
    this.calc(prices);
  },
  setSeats: function (seat) {
    $(".pricing-table tr[class^='js-pricing-table']").find(':input').attr('max', seat);
  },
  setInputControlStates: function (input, value) {
    input.parents('.row.justify-content-around').find('.input-control').removeClass('disabled');
    if (input.attr('max') === value) {
      input.next().find('.input-control').addClass('disabled');
    } else if (input.attr('min') === value) {
      input.prev().find('.input-control').addClass('disabled');
    }
  },
  calc: function (prices) {
    let totalCount = 0;
    let totalPrice = 0;
    for (const type in prices) {
      const row = $(`.js-pricing-table__row-${type}`);
      const count = +row.find(':input').val();
      const calc = prices[type] * count;
      totalCount += count;
      totalPrice += calc;
    }
    this.setTotal(totalCount, totalPrice);
  },
  setTotal: function (totalCount, totalPrice) {
    $('.js-count-total span').text(totalCount);
    totalPrice = numeral(totalPrice).format();
    $('.js-price-total span').text(totalPrice);
  },
};

const formValidation = {
  validateByType: function (input) {
    if (is.undefined(input)) {
      return false;
    }

    let validateResult;
    if (input.is('#order_first_name, #order_last_name')) {
      validateResult = this.validateTextField(input);
    } else if (input.is('#order_email')) {
      validateResult = this.validateEmail(input);
    } else if (input.is('#order_phone_1')) {
      validateResult = this.validatePhone(input);
    } else if (input.is('#order_number_adults_double, #order_number_adults_single')) {
      validateResult = this.validateNumber(input);
    }

    if (validateResult) {
      this.setValid(input);
    } else {
      this.setInvalid(input);
    }
    return true;
  },
  validateTextField: function (input) {
    return is.not.empty(input.val());
  },
  validateEmail: function (input) {
    return is.email(input.val());
  },
  validatePhone: function (input) {
    const regex = /^[0-9]{2,3}?[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/ig;
    return regex.test(input.val());
  },
  validateNumber: function (input) {
    return input.val() > 0;
  },
  setValid: function (input) {
    input.removeClass('is-invalid').addClass('is-valid');
    if (input.is('#order_number_adults_double, #order_number_adults_single')) {
      if ((parseInt($('#order_number_adults_double').val()) || parseInt($('#order_number_adults_single').val())) > 0) {
        $('#quantity').removeClass('invalid');
        $('.invalid-number-adults').hide();
      }
    }
  },
  setInvalid: function (input) {
    input.removeClass('is-valid').addClass('is-invalid');
    if (input.is('#order_number_adults_double, #order_number_adults_single')) {
      if ($('#order_number_adults_double').val() === '0' && $('#order_number_adults_single').val() === '0') {
        $('#quantity').addClass('invalid');
        $('html, body').animate({
          scrollTop: $('#quantity').offset().top - 50,
        }, 500, () => {
          $('.invalid-number-adults').fadeIn('slow');
        });
      }
    }
  },
  validateAll: function (numberOfField = 4) {
    const $submitButton = $("button[type='submit']");
    if ($('input[required].is-valid').length > numberOfField) {
      $submitButton.removeClass('disabled');
    } else {
      $submitButton.addClass('disabled');
    }
  },
};

const getRedirectTo = (url) => {
  const elems = document.querySelectorAll('#new_order [name^=order]:not([type=hidden]):not([id*=period_id])');
  _.each(elems, function (elem) {
    const param = url.searchParams.get(elem.name);
    if (param == null) {
      url.searchParams.set(elem.name, elem.value);
    } else {
      url.searchParams.append(elem.name, elem.value);
    }
  });

  return url;
};

export default {
  turbolinksLoad() {
    const orderForm = $('.new_order').length === 0 ? $('.edit_order') : $('.new_order');
    if (!orderForm.length && $('.order_wizards.show').length === 0) { return; }
    // auto setup prices
    // when render new form after failed
    const lengthOfValidatedField = orderForm[0].action.includes('confirm_buyer_information') ? 3 : 4;
    let option = $("[name='order[period_id]'] :selected");
    let prices = period.getPrices(option);
    let seat = period.getSeats(option);
    priceTable.setNewPrices(prices);
    priceTable.setSeats(seat);

    orderForm.find('select').change(function () {
      option = $('option:selected', this);
      prices = period.getPrices(option);
      seat = period.getSeats(option);
      priceTable.setNewPrices(prices);
      priceTable.setSeats(seat);
    });

    orderForm.find("input[type='number']").change(function () {
      const input = $(this);
      const value = input.val();
      priceTable.setNewPrices();
      priceTable.setInputControlStates(input, value);
    });

    orderForm.find('input[required]').on('change keyup', function () {
      const input = $(this);
      formValidation.validateByType(input);
      formValidation.validateAll(lengthOfValidatedField);
    });

    orderForm.find("input:not([type='number'], #order_promotion)").on('change keyup', function () {
      const input = $(this);
      formValidation.validateByType(input);
    });

    $('.input-control').click(function () {
      const elem = $(this);
      const method = elem.data('method');
      const input = elem.closest('.row').find(':input');
      const current = parseInt(input.val());
      const max = parseInt(input.attr('max'));
      if (current === max && method === 'more') {
        return;
      } else if (method === 'more') {
        input.val(current + 1);
        elem.closest('.row').find("[data-method='less']").removeClass('disabled');
      } else if (current !== 0) {
        input.val(current - 1);
        if (current === 1) {
          elem.addClass('disabled');
        }
      }
      input.trigger('change');
    });

    orderForm.find("button[type='submit']").on('click', () => {
      orderForm.find(':input[required]').each(function () {
        formValidation.validateByType($(this));
      });
      formValidation.validateAll(lengthOfValidatedField);
    });

    orderForm.on('submit', function (e) {
      $(e.target).find(':input[required]').each(function () {
        formValidation.validateByType($(this));
      });
      formValidation.validateAll(lengthOfValidatedField);
      return !orderForm.find("button[type='submit']").hasClass('disabled');
    });

    $('.order-facebook-oauth').on('click', function () {
      const redirectTo = getRedirectTo(new URL($(this).data('redirect-to')));
      const url = new URL($(this).data('url'));
      url.searchParams.set('redirect_to', redirectTo.href);
      window.location.href = url.href;
    });

    $('#order_form_sign_in_modal form,#order_form_sign_up_modal form').on('submit', function (e) {
      const form = e.target;
      const redirectTo = form.querySelector('[id*=redirect_to]');
      const url = getRedirectTo(new URL(redirectTo.value));
      redirectTo.value = url;
    });
  },
};
