'strict mode';

/* eslint no-new:0 */
// Modules
import Bookmark from './modules/bookmark';
import Blog from './modules/blog';
import CategoryBlog from './modules/category-blog';
import ClickToCopy from './modules/click-to-copy';
import CollectionShow from './modules/collection-show';
import CompareTableScalable from './modules/compare-table-scalable';
import CurationCarousel from './modules/curation-carousel';
import DatePicker from './modules/datepicker';
import Dropdown from './modules/dropdown';
import EmailSubscription from './modules/email-subscription';
import GaEventTracking from './modules/ga-event-tracking';
import ImgUploaderPreview from './modules/img-uploader-preview';
import Lazyload from './modules/lazyload';
import Navbar from './modules/navbar';
import OrderForm from './modules/order-form';
import PrivateOrderForm from './modules/private-order-form';
import ReadMore from './modules/read-more';
import ReccommendedBlock from './modules/reccommended-block';
import ScrollViewportControl from './modules/scroll-viewport-control';
import SharingModal from './modules/sharing-modal';
import TourInfos from './modules/tour-infos';
import TourSearch from './modules/tour-search';
import VideoSelector from './modules/video-selector';
import Job from './modules/job';
import SearchFilter from './modules/search-filter';
import SearchScrollControl from './modules/search-scroll-control';
import OwlCarousel3Way from './modules/owl-carousel-3way';
import SubscribeLineNotify from './modules/subscribe-line-notify';
import CarouselSwiper from './modules/carousel-swiper';
import TogglePassword from './modules/toggle-password';
import CareerForm from './modules/career-form';
import CareerNav from './modules/career-nav';
import formWatcher from './modules/form-watcher';
import formValidator from './modules/form-validation';
import TabList from './modules/tablist';
import HomeSearch from './modules/home-search';
import FieldTestConversion from './modules/field-test-conversion';
import PeriodCard from './modules/period-card';
import PeriodCardFilter from './modules/period-card-filter';
import SignInModal from './modules/sign-in-modal';
import SignUpModal from './modules/sign-up-modal';
import OrderWizards from './modules/order-wizards';
import Toast from './modules/toast';
import InitializeScroll from './modules/initialize-scroll';
import mobileAppStickyBanner from './modules/mobile-app-sticky-banner';
import CarouselApp from './modules/owl-carousel-app';
import MobileAppLanding from './modules/mobile-app-landing';
import ContactPopover from './modules/contact-popover';
import PeriodTooltip from './modules/period-tooltip';

// Components
import ButtonShare from './components/buttons-share';
import ShareTooltips from './components/share-tooltips';

// Service Worker
import './modules/serviceworker-companion';

// Router
import router from './modules/router';

// Search
import AngoliaSearch from './modules/algoliasearch';

// Turbolinks Before Cache
document.addEventListener('turbolinks:before-cache', function () {
  Blog.turbolinksBeforeCache();
  Bookmark.turbolinksBeforeCache();
  CategoryBlog.turbolinksBeforeCache();
  CurationCarousel.turbolinksBeforeCache();
  TourInfos.turbolinksBeforeCache();
  Job.turbolinksBeforeCache();
  SearchFilter.turbolinksBeforeCache();
  SearchScrollControl.turbolinksBeforeCache();
  OwlCarousel3Way.turbolinksBeforeCache();
  CarouselSwiper.turbolinksBeforeCache();
  CareerForm.turbolinksBeforeCache();
  HomeSearch.turbolinksBeforeCache();
  CarouselApp.turbolinksBeforeCache();
  Navbar.turbolinksBeforeCache();
});

// Turbolinks Load
document.addEventListener('turbolinks:load', function () {
  Blog.turbolinksLoad();
  CategoryBlog.turbolinksLoad();
  ClickToCopy.turbolinksLoad();
  CollectionShow.turbolinksLoad();
  CompareTableScalable.turbolinksLoad();
  CurationCarousel.turbolinksLoad();
  DatePicker.turbolinksLoad();
  Dropdown.turbolinksLoad();
  EmailSubscription.turbolinksLoad();
  GaEventTracking.turbolinksLoad();
  ImgUploaderPreview.turbolinksLoad();
  Navbar.turbolinksLoad();
  OrderWizards.turbolinksLoad();
  OrderForm.turbolinksLoad();
  PrivateOrderForm.turbolinksLoad();
  ReadMore.turbolinksLoad();
  ReccommendedBlock.turbolinksLoad();
  ScrollViewportControl.turbolinksLoad();
  SharingModal.turbolinksLoad();
  TourInfos.turbolinksLoad();
  TourSearch.turbolinksLoad();
  VideoSelector.turbolinksLoad();
  Job.turbolinksLoad();
  SearchFilter.turbolinksLoad();
  SearchScrollControl.turbolinksLoad();
  OwlCarousel3Way.turbolinksLoad();
  Bookmark.turbolinksLoad();
  SubscribeLineNotify.turbolinksLoad();
  CarouselSwiper.turbolinksLoad();
  CareerForm.turbolinksLoad();
  CareerNav.turbolinksLoad();
  TogglePassword.turbolinksLoad();
  formWatcher.turbolinksLoad();
  formValidator.turbolinksLoad();
  TabList.turbolinksLoad();
  HomeSearch.turbolinksLoad();
  FieldTestConversion.turbolinksLoad();
  PeriodCard.turbolinksLoad();
  PeriodCardFilter.turbolinksLoad();
  SignInModal.turbolinksLoad();
  SignUpModal.turbolinksLoad();
  Toast.turbolinksLoad();
  InitializeScroll.turbolinksLoad();
  Lazyload.turbolinksLoad();
  CarouselApp.turbolinksLoad();
  MobileAppLanding.turbolinksLoad();
  ContactPopover.turbolinksLoad();
  mobileAppStickyBanner.turbolinksLoad();
  PeriodTooltip.turbolinksLoad();
  new ButtonShare();
  new ShareTooltips();
});

document.addEventListener('turbolinks:before-visit', (e) => {
  const url = new URL(e.data.url);
  router.redirect(url);
});
