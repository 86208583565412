'strict mode';

const togglePeriodCardFilters = (monthFiltered) => {
  const periodCardFilters = document.querySelectorAll('[data-component="period-card-filter"]');

  periodCardFilters.forEach((periodCardFilter) => {
    const filterBy = periodCardFilter.dataset.filterBy;

    periodCardFilter.classList.toggle('active', filterBy === monthFiltered);
  });
};

const togglePeriodCards = (monthFiltered) => {
  const periodCards = document.querySelectorAll('[data-period-months]');

  periodCards.forEach((periodCard) => {
    const periodMonths = periodCard.dataset.periodMonths.split("/");

    periodCard.classList.toggle('period-card--hidden', !periodMonths.includes(monthFiltered));
  });
};

const blindFilterEvent = (periodCardFilter) => {
  const monthFiltered = periodCardFilter.dataset.filterBy;

  periodCardFilter.addEventListener('click', (event) => {
    const periodCardFilter = event.target;

    togglePeriodCardFilters(monthFiltered);
    togglePeriodCards(monthFiltered);
  });
};

export default {
  turbolinksLoad() {
    if (document.querySelectorAll('[data-component="period-card-filter"]') !== null) {
      const periodCardFilters = document.querySelectorAll('[data-component="period-card-filter"]');

      periodCardFilters.forEach(blindFilterEvent);
    }
  }
};
