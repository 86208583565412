'strict mode';

function closeTooltip(button) {
  button.classList.remove('package-card__icon-view-more__button--active', 'from-click');
 }

function closeAllTooltip(e) {
  e.preventDefault();
  const activeButton = document.querySelectorAll('.package-card__icon-view-more__button--active')
  if(activeButton.length > 0 ) {
    activeButton.forEach((button)=> {
      closeTooltip(button)
    })
  }
}
function addEventForClickTooltip(e, button, parentOfParent, tooltip) {
  e.preventDefault();
  e.stopPropagation();
  if(button.classList.contains('package-card__icon-view-more__button--active', 'from-click')) {
    closeTooltip(button)
    button.classList.remove('package-card__icon-view-more__button--active', 'from-click');
  } else {
    tooltip.style.width = `${(parentOfParent.offsetWidth)+32}px`
    button.classList.add('package-card__icon-view-more__button--active', 'from-click');
  }
}

function addEventForViewMorePeriod(e, button) {
  e.preventDefault();
  e.stopPropagation();

  const redirectTo = button.getAttribute('data-to');
  window.location.href = redirectTo;
}

function onPeriodTooltipClick() {
  document.querySelectorAll('.package-card__icon-view-more__button').forEach((button) => {
    const parent = button.parentNode;
    const parentOfParent = parent.parentNode;
    const tooltip = parentOfParent.getElementsByClassName('package-card__icon-view-more__tooltip')[0];
    const closeTooltipButton = tooltip.getElementsByClassName('package-card__tooltip-close-btn')[0]
    const viewMorePeriodButtom = tooltip.getElementsByClassName('package-card__view-more-link')[0];

    closeTooltipButton.addEventListener('click', (e) => { addEventForClickTooltip(e, button, parentOfParent, tooltip) });
    button.addEventListener('click', (e) => { addEventForClickTooltip(e, button, parentOfParent, tooltip) });
    viewMorePeriodButtom.addEventListener('click', (e) => {addEventForViewMorePeriod(e, viewMorePeriodButtom)})
  });
}


export default {
  turbolinksLoad() {
    onPeriodTooltipClick();
  }
};
