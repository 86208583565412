import favGuideImages from '../../../images/search/fav-guide.png';

const cookies = {
  set: (cookieName, cookieValue, expiresDays) => {
    const d = new Date();
    d.setTime(d.getTime() + (expiresDays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
  },
  get: (cookieName) => {
    const name = `${cookieName}=`;
    const cookie = document.cookie.split('; ').filter(c => c.includes(name));
    return cookie.length === 1 ? cookie[0].split('=')[1] : 'false';
  },
};

const alertBoxContext = {
  images: () => {
    const img = document.createElement('img');
    img.classList.add('img-fluid', 'rounded');
    img.setAttribute('src', favGuideImages);
    return img;
  },
  closeBtn: () => {
    const closedBtn = document.createElement('i');
    closedBtn.classList.add('fa', 'fa-times', 'd-inline-block');
    closedBtn.setAttribute('style', 'position: absolute; top: 10px; right: 10px;');
    closedBtn.addEventListener('click', function (e) {
      const elem = e.target;
      elem.parentNode.remove(elem);
    });
    return closedBtn;
  },
  addAttribute: (div) => {
    div.classList.add('alert', 'mx-auto', 'p-0', 'rounded', 'd-lg-none', 'mb-4');
    div.setAttribute('style', 'width: 95%');
    return div;
  },
  insertContext: function (div) {
    div.append(this.images());
    div.append(this.closeBtn());
    return div;
  },
};

function addUserTooltipsGuide() {
  const bookmarkLinks = document.querySelector('.tour-card .js-bookmark-links');
  bookmarkLinks.setAttribute('data-toggle', 'tooltip');
  bookmarkLinks.setAttribute('data-placement', 'left');
  bookmarkLinks.setAttribute('title', 'ถูกใจ เพื่อเปรียบเทียบ');
  $('[data-toggle="tooltip"]').tooltip('show');
}

function addUserGuidelineBox() {
  // select first card node and links
  const firstCard = document.querySelector('.tour-card');

  // prev alert div
  const div = document.createElement('div');
  alertBoxContext.addAttribute(div);
  alertBoxContext.insertContext(div);

  // append alert div after first card
  firstCard.parentNode.insertBefore(div, firstCard.nextSibling);
}

function checkCookies() {
  const isFirstEnter = (cookies.get('had_visited') === 'false');
  if (isFirstEnter) {
    cookies.set('had_visited', true, 365);
    addUserTooltipsGuide();
    addUserGuidelineBox();
  }
}

export default {
  checkCookies,
};
