'strict mode';

function watchInputChange(changedInput, unchangedInput, submitButton) {
  var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      const value = mutation.target.value;
      if(value > 0) {
        submitButton.classList.remove('disabled');
        submitButton.disabled = false;
      } else if(unchangedInput.value == 0 && value == 0) {
        submitButton.classList.remove('disabled');
        submitButton.classList.add('disabled');
        submitButton.disabled = true;
      }
    });
  });
  observer.observe(changedInput, {attributes: true});
}

function checkNumberOfPeopleInput() {
  const submitButton = document.querySelector(".edit_order input[type='submit']");
  const adultsDoubleInput = document.getElementById('order_number_adults_double');
  const adultsSingleInput = document.getElementById('order_number_adults_single');
  watchInputChange(adultsDoubleInput, adultsSingleInput, submitButton);
  watchInputChange(adultsSingleInput, adultsDoubleInput, submitButton);
}

function filterPeriod() {
  const monthFilter = document.querySelector('.btn-month-period.active').dataset.month;
  document.querySelectorAll('tr[data-month]').forEach(function(element) {
    const month = element.dataset.month;
    if(monthFilter === 'all') {
      element.style.display = 'table-row';
    } else if(month !== monthFilter) {
      element.style.display = 'none';
    } else  {
      element.style.display = 'table-row';
    }
  })
}

function activeButtonFilter() {
  document.querySelectorAll('.btn-month-period').forEach(function(button) {
    button.addEventListener('click', function() {
      const buttons = button.parentNode.children;
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove('active');
      }
      button.classList.add('active');
      filterPeriod();
    })
  })
}

export default {
  turbolinksLoad() {
    const wizardPage = document.querySelector('.order_wizards.show');
    const wizardForm = document.querySelector('form.edit_order');
    if (wizardPage != null && wizardForm != null) {
      if(wizardForm.action.includes('confirm_no_of_people')) {
        checkNumberOfPeopleInput();
      }
    }

    const currentUrl = window.location.href;
    if(wizardPage != null && (currentUrl.includes('confirm_period') || currentUrl.includes('review_order'))) {
      activeButtonFilter();
    }
  }
};
