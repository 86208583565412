const formWatch = {
  getSubmitInput: form => form.querySelector('[type="submit"]'),
  bindOnchangeEvent: (form) => {
    form.querySelectorAll('input').forEach((input) => {
      input.dataset.value = input.value;
      input.addEventListener('change', (e) => {
        const elem = e.target;
        const isChanged = formWatch.checkInput(elem);
        formWatch.changedToggle(isChanged, elem);
        formWatch.checkForm(form);
      });
    });
  },
  checkInput: (input) => {
    if (input.dataset.value === undefined || null) return false;
    return input.value === input.dataset.value;
  },
  checkForm: (form) => {
    if (form.querySelector('.changed') === null) {
      formWatch.disableSubmit(form);
    } else {
      formWatch.enableSubmit(form);
    }
  },
  changedToggle: (isChanged, elem) => {
    if (isChanged) {
      elem.classList.add('changed');
    } else {
      elem.classList.add('changed');
    }
  },
  enableSubmit: (form) => {
    const input = formWatch.getSubmitInput(form);
    input.classList.remove('disabled');
    input.disabled = false;
  },
  disableSubmit: (form) => {
    const input = formWatch.getSubmitInput(form);
    input.classList.add('disabled');
    input.disabled = true;
  },
};

export default {
  turbolinksLoad() {
    if (document.querySelector('.form-watcher')) {
      const form = document.querySelector('.form-watcher');
      formWatch.checkForm(form);
      formWatch.bindOnchangeEvent(form);
    }
  },
};
