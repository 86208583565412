'strict mode';

import QuerySelector from '../../shared/query-selector';

export default class {
  constructor() {
    this.$element = QuerySelector.data('data-component-name', 'share-buttons');
    const shareButtonRendered = document.querySelector('.btn-copy-url') !== null
    if (this.$element.length > 0  && !shareButtonRendered) {
      this.render();
      this.bindCopyTextEvent();
    }
  }

  get options() {
    return {
      facebook: {
        target: '_blank',
        alt: 'แชร์ผ่าน Facebook',
        class: 'btn-share-facebook',
        height: 35.4,
      },
      messenger: {
        target: '_blank',
        alt: 'แชร์ผ่าน Facebook messenger',
        class: 'btn-share-messenger',
        height: 35.4,
      },
      line: {
        target: '_blank',
        alt: 'แชร์ผ่าน Line',
        class: 'btn-share-line',
        height: 35.4,
      },
      email: {
        target: null,
        alt: 'แชร์ผ่าน Email',
        class: 'btn-share-email',
        height: 35.4,
      },
      link_for_copy: {
        target: null,
        alt: 'Copy url',
        class: 'btn-copy-url',
        height: 35.4
      }
    };
  }

  getItemsElement(options) {
    const keys = Object.keys(this.options);
    let contents = '';
    keys.forEach((key) => {
      contents += `
      <a
        ${this.options[key].target ? `target="${this.options[key].target}"` : ''}
        style="position: relative;"
        class="${this.options[key].class}"
        href="${options.json[`link_${key}`]}">
          <img
            style="margin-bottom:5px;"
            alt="${this.options[key].alt}"
            class="lazyload"
            height="${this.options[key].height * options.multipleHeight}""
            src="${options.json[`image_${key}`]}"">
      </a>`;
    });

    const $element = document.createElement('div');
    $element.className = `text-${options.align}`;
    $element.innerHTML = contents;
    return $element;
  }

  bindCopyTextEvent() {
    const copyUrlBtn = document.querySelector('.btn-copy-url')
    copyUrlBtn.addEventListener('click', function(e){
      e.preventDefault();
      const url = window.location.href;
      let dummy = document.createElement("input");
      dummy.value = url;
      dummy.setAttribute('readonly', '');
      dummy.style.position = 'absolute';
      dummy.style.left = '-9999px';
      document.body.appendChild(dummy);
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    })
  }

  render() {
    this.$element.forEach(($element) => {
      const options = {
        json: JSON.parse($element.getAttribute('data-json')) ? JSON.parse($element.getAttribute('data-json')) : null,
        align: $element.getAttribute('data-align') ? $element.getAttribute('data-align') : 'center',
        multipleHeight: $element.getAttribute('data-multiple-height') ? parseFloat($element.getAttribute('data-multiple-height')) : 1,
      };
      $element.appendChild(this.getItemsElement(options));
    });
  }
}
