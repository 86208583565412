/* eslint-disable no-console */
/* eslint-disable import/first */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'jquery';
import 'jquery-ujs';
import 'popper.js';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/util';
import 'lazysizes';
import 'lodash';
import 'jquery.scrollto';
import 'bootstrap-datepicker';
import 'owl.carousel';
import 'ion-rangeslider';
import 'expose-loader?$!jquery'; // expose jquery to window

import Turbolinks from 'turbolinks';
import moment from 'moment';
import numeral from 'numeral';

Turbolinks.start();
moment.locale('th');
numeral.defaultFormat('0,0');

import 'stylesheets/application';
import './application/index.js';

// Import all images under images directory
require.context('../images', true);
require.context('../fonts', true);

// Support component names relative to this directory:
// const componentRequireContext = require.context('components', true);
// const ReactRailsUJS = require('react_ujs');

// ReactRailsUJS.useContext(componentRequireContext);
