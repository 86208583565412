const signUpModal = {
  bindOnSubmitFormEvent: (form) => {
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      const url = form.getAttribute('action');
      const formData = new FormData(form);
      const request = new XMLHttpRequest();
      request.open('POST', url, true);
      request.send(formData);
      request.onload = () => {
        signUpModal.requestOnloadAction(form, request);
      };
    });
  },
  setInvalid: (input) => {
    input.classList.add('is-invalid');
    input.classList.remove('is-valid');
  },
  unauthenticateFeedback: {
    show: (elem) => {
      const unauthenticateElem = elem.querySelector('.unauthenticate');
      unauthenticateElem.classList.remove('d-none');
      unauthenticateElem.classList.add('d-block');

      const invalidFormatElem = elem.querySelector('.invalid-format');
      invalidFormatElem.classList.remove('d-block');
      invalidFormatElem.classList.add('d-none');
    },
    hide: (elem) => {
      const unauthenticateElem = elem.querySelector('.unauthenticate');
      unauthenticateElem.classList.remove('d-block');
      unauthenticateElem.classList.add('d-none');

      const invalidFormatElem = elem.querySelector('.invalid-format');
      invalidFormatElem.classList.remove('d-none');
      invalidFormatElem.classList.add('d-block');
    },
  },
  requestOnloadAction: (form, request) => {
    if (request.status === 200) {
      window.location.reload();
    } else {
      const emailInput = form.querySelector('#sign_up_modal_email');
      signUpModal.setInvalid(emailInput);
      signUpModal.unauthenticateFeedback.show(form);
      emailInput.addEventListener('input', () => {
        signInModal.unauthenticateFeedback.hide(form);
      }, { once: true });
    }
  },
  checkDisabledSubmitForm: () => {
    if ($('#user_email').hasClass('is-invalid') || ($('#user_password').hasClass('is-invalid'))) {
      $('#submit-sign-up').attr('disabled', true);
    } else {
      $('#submit-sign-up').removeAttr('disabled');
    }
  },
};

export default {
  turbolinksLoad() {
    const form = document.getElementById('sign_up_modal_form');
    const emailInput = document.getElementById('user_email');
    const passwordInput = document.getElementById('user_password');
    if (form && emailInput && passwordInput) {
      signUpModal.bindOnSubmitFormEvent(form);
      emailInput.addEventListener('input', () => {
        signUpModal.checkDisabledSubmitForm();
      });
      passwordInput.addEventListener('input', () => {
        signUpModal.checkDisabledSubmitForm();
      });
    }
  },
};
