'strict mode';

export default {
  turbolinksLoad() {
    const compareTable = $('.compare--table');
    const breakpoint = 992;
    const containerBreakpoint = 576;
    let windowWidth = window.innerWidth;
    let containerWidth = windowWidth <= containerBreakpoint ? windowWidth : $('body').find('.scollable-viewport').width();
    let visibleCount = windowWidth <= breakpoint ? 2 : 4;
    let cellsWidth = Math.round(containerWidth / visibleCount);
    if (compareTable.length) {
      compareTable.find('td').each(function () {
        if ($(this).is(':first-child, :last-child')) {
          $(this).css('min-width', cellsWidth);
        } else {
          $(this).css('min-width', (cellsWidth + 10));
        }
      });
    }

    window.addEventListener('resize', () => {
      windowWidth = window.innerWidth;
      containerWidth = windowWidth <= containerBreakpoint ? windowWidth : $('body').find('.scollable-viewport').width();
      visibleCount = windowWidth <= breakpoint ? 2 : 4;
      cellsWidth = Math.round(containerWidth / visibleCount);
      compareTable.find('td').each(function () {
        if ($(this).is(':first-child, :last-child')) {
          $(this).css('min-width', (cellsWidth));
        } else {
          $(this).css('min-width', (cellsWidth + 10));
        }
      });
    });
  },
};
