'strict mode';

export default {
  turbolinksLoad() {
    $('.read-more-trigger').unbind('click').on('click', function (e) {
      e.preventDefault();

      const $button = $(e.currentTarget);
      $button.toggleClass('read-more-triggered');

      const $wrap = $button.prev('.read-more-wrap');
      const $targets = $wrap.find('.read-more-target');

      if ($button.hasClass('read-more-triggered')) {
        $targets.addClass('read-more-target-shown');
        $button.text('ย่อลง');
      } else {
        $targets.removeClass('read-more-target-shown');
        $button.text('ดูเพิ่มเติม');
      }
    });
  },
};
