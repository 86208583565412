'strict mode';

export default {
  options: { year: 'numeric', month: 'long', day: 'numeric' },
  turbolinksLoad() {
    $('.js-datepicker').on('keypress', () => false);
    $('.js-datepicker').datepicker({
      language: 'th',
      container: '.birth_date',
      format: {
        toDisplay: function (date) {
          const d = new Date(date);
          const birthDayResult = document.querySelector('.birth-day-result');
          birthDayResult.setAttribute('value', d);
          birthDayResult.dispatchEvent(new Event('change'));
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          return d.toLocaleDateString('th-th', options);
        },
        toValue: function (date) {
          const d = new Date(date);
          return new Date(d);
        },
      },
    });
  },
};
