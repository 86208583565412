'strict mode';

import is from 'is_js';
import Selectr from 'mobius1-selectr';

import liveSearch from './live-search';
import ga from './ga-event-tracking';
import { SMALL_DEVICES_WIDTH } from '../../shared/constants';

function bindOnNavSearchEvent(elem) {
  elem.addEventListener('submit', (e) => {
    const form = e.target;
    const idInput = form.querySelector('#search_id');
    const queryInput = form.querySelector('#search_input');
    const selectInput = form.querySelector('#month_nav_search');
    const optionalInput = form.querySelectorAll('[title=optional_input]');
    // set disable not used input
    if (idInput.value === '') {
      idInput.disabled = true;
    } else {
      queryInput.disabled = true;
    }
    if (selectInput.value === '') {
      selectInput.disabled = true;
    }
    // get/set form action before send
    const searchType = queryInput.dataset.type;
    let inputName = '';
    if (searchType === 'collections') {
      inputName = 'collections[]';
    } else if (is.empty(searchType)) {
      optionalInput.disabled = true;
    } else {
      inputName = 'route[]';
    }

    // if founded any search type then disable query and set all optional input
    if (is.not.empty(searchType)) {
      optionalInput.forEach(function (i) {
        i.removeAttribute('disabled');
        i.setAttribute('name', inputName);
      });
      queryInput.disabled = true;
    }
  });
  ga.sendUseSearchTrackingToGA(elem);
}

const close = {
  removeOverlay: function () {
    $('body').find('.overlay').addClass('hiding').delay(250)
      .queue(function (next) {
        $(this).remove();
        next();
      });
  },
  menu: function () {
    this.removeOverlay();
    $('#navbarSupportedContent').addClass('closing').delay(250).queue(function (next) {
      $(this).removeClass('show closing slide-left').removeAttr('style');
      $('body').removeClass('visible-overlay');
      next();
    });
  },
  contact: function () {
    this.removeOverlay();
    $('#contact-popup').removeClass('slide-up');
    $('body').delay(400).queue(function (next) {
      $(this).removeClass('visible-overlay');
      next();
    });
  },
  searchBox: function () {
    const clonedModal = $('.search-box.modal-cloned');
    this.removeOverlay();
    liveSearch.close();
    $('.js-mobile-searchBox').removeClass('opened');
    clonedModal.removeClass('slide-down');
    $('body').delay(400).queue(function (next) {
      $(this).removeClass('visible-overlay');
      clonedModal.remove();
      next();
    });
  },
};

const deskTopNav = {
  setHoverActive: (node, currentActive) => {
    if (window.innerWidth <= Math.round(SMALL_DEVICES_WIDTH) || node.classList.contains('active') || currentActive === null) return;
    node.classList.add('active');
  },
  unsetHoverActive: (node, currentActive) => {
    if (window.innerWidth <= Math.round(SMALL_DEVICES_WIDTH) || currentActive === null) return;
    currentActive.classList.add('active');
    node.classList.remove('active');
  },
};

const monthSelectr = {
  destroy: (instance) => {
    if (typeof window.monthSelectr === 'object' && instance == null) instance = window.monthSelectr;
    instance.destroy();
  },
  reinit: (selectInput, instance) => {
    if (selectInput == null) return;
    if (typeof window.monthSelectr === 'object' && instance == null) window.monthSelectr.destroy();
    monthSelectr.init(selectInput);
  },
  init: (selectInput) => {
    window.monthSelectr = new Selectr(selectInput, {
      searchable: false,
      defaultSelected: false,
      customClass: 'month_selectr',
      placeholder: 'เลือกช่วงเดือนเดินทาง',
    });
  },
};

export default {
  turbolinksLoad() {
    const collapseNav = $('#navbarSupportedContent');
    const navOpen = $('.menu-toggler');
    const navClose = collapseNav.find('.close-btn');
    const collections = $('#collection');
    const contact = $('.contact-popup');
    const megaMenuTrigger = $('.mega-menu');
    const searchTrigger = $('.js-mobile-searchBox');
    const iconCollectionMobile = $('#collection-mobile');
    const breakpoint = 991;
    const desktopForm = document.querySelector('#searchBox + .search-box form');
    const deskTopNavItem = document.querySelectorAll('#navbarSupportedContent .navbar-nav:not(.d-lg-none) .nav-item:not(.d-lg-none) .nav-link:not(.d-lg-none)');
    if (desktopForm !== null) {
      const autoCompleteInput = desktopForm.querySelector('#search_input');
      const selectInput = desktopForm.querySelector('#month_nav_search');
      monthSelectr.init(selectInput);
      bindOnNavSearchEvent(desktopForm);
      autoCompleteInput.addEventListener('focus', liveSearch.attachEventList, false);
    }

    $('.main-navbar .navbar .center').css('max-width', window.innerWidth / 2);

    navOpen.on('click', function () {
      const body = $('body');
      if (body.hasClass('modal-open')) return;
      body.addClass('visible-overlay');
      $('<div/>', { class: 'overlay animated' }).height(window.innerHeight).appendTo('.main-navbar .navbar-expand-lg').delay(250)
        .queue(function (next) {
          next();
          $(this).bind('click', function () {
            close.menu();
          });
        });
      collapseNav.height(window.innerHeight + 1).addClass('show').delay(250).queue(function (next) {
        $(this).addClass('slide-left');
        next();
      });
    });

    navClose.on('click', function () {
      close.menu();
    });

    iconCollectionMobile.on('click', () => {
      collections.stop(true, false).slideToggle();
    });

    $(window).on('resize', function () {
      if (window.innerWidth >= breakpoint) {
        close.menu();
        close.contact();
        close.searchBox();
        if (collections.css('display') === 'block') {
          collections.hide();
        }
      } else {
        collapseNav.height(window.innerHeight + 1);
        $('body').find('.overlay').height(window.innerHeight);
        $('#contact-popup').width(window.innerWidth - 30);
        $('.main-navbar .navbar .center').css('max-width', window.innerWidth / 2);
      }
    });

    ['hide.bs.collapse', 'show.bs.collapse'].forEach(event =>
      collections.on(event, function () {
        const collapseToggler = $('[href="#collection"]');
        collapseToggler.parent().toggleClass('collapse-opened');
      }));

    contact.on('click', function () {
      $('body').toggleClass('visible-overlay');
      $('#contact-popup').width(window.innerWidth - 30).toggleClass('slide-up');
      $('<div/>', { class: 'overlay animated' }).appendTo('.main-navbar').delay(400).queue(function (next) {
        next();
        $(this).bind('click', function () {
          close.contact();
        });
      });
    });

    $('#contact-popup').find('h5 .close-btn').click(function () {
      close.contact();
    });

    $('.close-collection').click(function () {
      collections.collapse('toggle');
    });

    megaMenuTrigger.hover(() => {
      if (window.innerWidth < breakpoint) return;
      collections.stop(true, false).slideToggle();
      megaMenuTrigger.toggleClass('hovered');
      megaMenuTrigger.closest('.main-navbar').toggleClass('collections-opened');
    });

    searchTrigger.click((e) => {
      const elem = $(e.target);
      if (elem.hasClass('opened')) {
        close.searchBox();
      } else {
        $('body').addClass('visible-overlay');
        elem.addClass('opened');
        if (!$('.overlay.animated').length) {
          $('<div/>', { class: 'overlay animated' }).appendTo('body').delay(400).queue(function (next) {
            next();
            $(this).bind('click', function () {
              close.searchBox();
            });
          });
          if (!$('.search-box.modal-cloned').length) {
            monthSelectr.destroy();
            $('.search-box').clone(true, true).addClass('modal-cloned d-block')
              .appendTo('.main-navbar')
              .delay(200)
              .queue(function (next) {
                next();
                bindOnNavSearchEvent(this);
                monthSelectr.reinit(this.querySelector('#month_nav_search'));
                this.querySelector('#search_input').addEventListener('focus', liveSearch.attachEventList, false);
                $(this).addClass('slide-down');
              });
          }
        }
      }
    });

    deskTopNavItem.forEach((navLink) => {
      const currentActive = navLink.closest('.navbar-nav').querySelector('.active');
      navLink.onmouseover = () => {
        deskTopNav.setHoverActive(navLink, currentActive);
      };

      navLink.onmouseout = () => {
        deskTopNav.unsetHoverActive(navLink, currentActive);
      };
    });
  },
  turbolinksBeforeCache() {
    monthSelectr.destroy();
  },
};
