import { SMALL_DEVICES_WIDTH } from '../../shared/constants';

export default {
  turbolinksLoad() {
    const popoverElems = document.querySelectorAll('[data-toggle="popover"]');
    popoverElems.forEach((elem) => {
      elem.addEventListener('click', (e) => {
        // toggle popver only for desktop & else open an link
        if (window.innerWidth >= Math.round(SMALL_DEVICES_WIDTH)) {
          const popOverDom = $(e.target).closest("[class*='-link']");
          popOverDom.popover('toggle');
          e.preventDefault();
        }
      });
    });
  },
};
