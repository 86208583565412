'strict mode';

export default {
  turbolinksLoad() {
    const url = new URL(document.URL);
    const scroll = url.searchParams.get('scroll');
    if (scroll) {
      document.documentElement.scrollTop = scroll;
    }
  },
}
