'strict mode';

import toastr from 'toastr/build/toastr.min';
import { LARGE_DEVICES_BREAKPOINT } from '../../shared/constants';

class Toast {
  constructor() {
    this.breakPoint = LARGE_DEVICES_BREAKPOINT;
    this.optionsDefault = {
      debug: false,
      timeOut: 3000,
      extendedTimeOut: 100,
      newestOnTop: false,
      closeDuration: 0,
    };
    this.optionsFlash = {
      closeButton: true,
      debug: false,
      timeOut: 3000,
      extendedTimeOut: 100,
      newestOnTop: false,
      closeDuration: 0,
    };
  }

  defaultMessages() {
    const createBookmarkSuccess = `
      <div class="float-left"><i class="fas fa-info-circle mr-2"></i>เพิ่มใน "แพ็กเกจถูกใจ" แล้ว</div>
      <div class="float-right"><a href="/users/bookmark">&nbsp;<u>ดูทั้งหมด</u></a></div>`;
    const destroyBookmarkSuccess = '<i class="fas fa-info-circle mr-2"></i>นำออกจาก "แพ็กเกจถูกใจ" แล้ว';

    return {
      createBookmarkSuccess,
      destroyBookmarkSuccess,
    };
  }

  flashMessage(messageText, flashType) {
    let icon = '';
    if (flashType === 'success') {
      icon = '<i class="fas fa-check-circle"></i>';
    } else {
      icon = `<i class="fas fa-exclamation-circle ${flashType}"></i>`;
    }
    return `${icon}<div>${messageText}</div>`;
  }

  start() {
    window.addEventListener('navigate', (_, data) => {
      const { direction } = data.state;
      if (direction === 'forward') {
        this.destroy();
      }
    });

    const toastMessages = document.querySelectorAll('.toast-message');
    toastMessages.forEach((message) => {
      this.create(message.innerHTML);
    });

    const toastFlashMessage = document.querySelectorAll('.toast-flash-message');
    toastFlashMessage.forEach((message) => {
      this.create(message.innerHTML, true, message.getAttribute('type'));
    });
  }

  create(messageKey, isFlashMessage = false, flashType = null) {
    let messages = '';
    if (isFlashMessage) {
      if (flashType === null) return;
      messages = this.flashMessage(messageKey, flashType);
    } else {
      messages = this.defaultMessages()[messageKey];
    }
    toastr.options = isFlashMessage ? this.optionsFlash : this.optionsDefault;
    toastr.options.positionClass = window.innerWidth < this.breakPoint ? 'toast-bottom-full-width' : 'toast-bottom-left';
    toastr.success(messages, '', { iconClass: 'toast-default' });
  }

  destroy() {
    toastr.clear();
  }
}

const toast = new Toast();

export default {
  turbolinksLoad() {
    toast.start();
  },
  add(messageKey) {
    toast.destroy();
    toast.create(messageKey);
  },
};
