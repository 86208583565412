'strict mode';

import goToTopButtonInitializer from './top-btn';

export default {
  turbolinksLoad() {
    if ($('.tours.searches').length > 0) {
      goToTopButtonInitializer($('.btn-go-to-top'));
    }
  },
};
