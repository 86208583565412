'strict mode';
import InfiniteScroll from "infinite-scroll";
import ShareTooltips from "../components/share-tooltips";

export default {
  turbolinksLoad() {
    if( document.querySelector(".blog_posts.lists.show") === null) { return }
    var menu = document.querySelector(".category__blog__filter-menu .nav");
    var nextButton = document.querySelector(".category-blog__filter-menu__nav-next")
    var prevButton = document.querySelector(".category-blog__filter-menu__nav-previous")

    nextButton.addEventListener('click',function() {
      menu.scrollLeft += 150
    }, false);
    prevButton.addEventListener('click',function() {
      menu.scrollLeft -= 150
    }, false);

    if (document.querySelector(".js-category-blog__list-wrapper") !== null) {
      var scrolling = new InfiniteScroll(".js-category-blog__list-wrapper", {
        path: 'nav.pagination a[rel=next]',
        append: '.category-blog__blog-card',
        hideNav: 'nav.pagination',
        history: 'push',
        prefill: true,
      });

      scrolling.on('append', function(response, path, items) {
        new ShareTooltips();
      });
    }
  },
  turbolinksBeforeCache() {
    if (document.querySelector(".js-category-blog__list-wrapper") === null) { return }
    var scrolling = new InfiniteScroll(".js-category-blog__list-wrapper")
    scrolling.destroy();
  }
}
