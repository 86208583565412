import {
  IPAD_PRO_WIDTH,
  SMALL_DEVICES_WIDTH,
  TOUR_NAV_HEIGHT,
  MAIN_TOP_NAVBAR_HEIGHT,
} from './constants';

function smallerThanOrEqualSmallDevicesWidth() {
  return $('body').width() <= SMALL_DEVICES_WIDTH;
}

export function animateToOffset(offset, amimateDuration) {
  $('html,body').animate({ scrollTop: offset }, amimateDuration);
}

export function waitAndAnimateToOffset(offset, animateDuration, timeout) {
  setTimeout(() => { animateToOffset(offset, animateDuration); }, timeout);
}

export function greatherThanOrEqualIpadProWidth() {
  return $('body').width() >= IPAD_PRO_WIDTH;
}

export function minusOffSetDueToTourNavHeight(offset) {
  if (greatherThanOrEqualIpadProWidth()) {
    return offset - TOUR_NAV_HEIGHT;
  } else if (smallerThanOrEqualSmallDevicesWidth()) {
    return offset - MAIN_TOP_NAVBAR_HEIGHT;
  }
  return offset;
}
