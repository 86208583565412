'strict mode';

function submitToSheetsu(value) {
  const elem = $('.subscription-box');
  $.ajax({
    method: 'POST',
    url: `/email_subscription?${value}`,
  }).done(function () {
    elem.find('form').next()
      .addClass('success');
  }).fail(function () {
    elem.find('form').next()
      .addClass('fail');
  }).always(function () {
    elem.find('form')
      .addClass('submitted')
      .css('max-height', 0);
  });
}

export default {
  turbolinksLoad() {
    // eslint-disable-next-line consistent-return
    $("[action='/email_subscription']").submit(function (e) {
      e.preventDefault();
      const elem = $(this);
      const email = elem.find('#email').val();
      if (email.length && is.email(email)) {
        const value = elem.serialize();
        elem.find("[type='submit']")
          .prop('disabled', true)
          .addClass('disabled');
        elem.find('#email')
          .prop('disabled', true)
          .removeClass('is-invalid')
          .addClass('disabled');
        submitToSheetsu(value);
      } else {
        elem.find('#email')
          .addClass('is-invalid');
        return false;
      }
    });
  },
};
