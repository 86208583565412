'strict mode';

export default {
  turbolinksBeforeCache() {

  },
  turbolinksLoad() {

  },
};
