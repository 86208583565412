import { ALGOLIA_SEARCH_API_KEY, ALGOLIA_APPLICATION_ID } from '../../shared/constants';

const algoliasearch = require('algoliasearch');
const autocomplete = require('autocomplete.js');

class AlgoliaEngine {
  constructor() {
    this.apiKey = ALGOLIA_SEARCH_API_KEY;
    this.applicationID = ALGOLIA_APPLICATION_ID;
    this.prefixes = () => {
      const location = window.location.href;
      let environment = '';
      if (/(staging)/.test(location)) {
        environment = 'staging';
      } else if (/(tourkrub)/.test(location)) {
        environment = 'production';
      } else {
        environment = 'development';
      }
      return environment;
    };
    this.option = {
      hint: false,
      templates: {
        empty: '<p>ไม่สามารถแสดงผลได้ในขณะนี้</p>' +
               '<p>กรุณาลองใหม่อีกครั้ง</p>',
      },
    };
  }
  searchClient() {
    if (typeof this.apiKey === 'undefined' || typeof this.applicationID === 'undefined') {
      console.error('Init algolia client failed');
      return null;
    }
    return algoliasearch(this.applicationID, this.apiKey);
  }
  searchIndexes() {
    const client = this.searchClient();
    if (!client) {
      console.error('Init algolia index failed');
      return null;
    }
    const index = this.prefixes();
    return {
      annotations: client.initIndex(`${index}_annotation`),
      routes: client.initIndex(`${index}_route`),
      collections: client.initIndex(`${index}_collection`),
    };
  }
  autoCompleteTarget(indexes = this.searchIndexes(), hitCount = 3, debounceTime = 500) {
    if (!indexes) {
      return null;
    }
    return [
      {
        debounce: debounceTime,
        source: autocomplete.sources.hits(indexes.annotations, { hitsPerPage: hitCount }),
        displayKey: 'name_th',
        templates: {
          suggestion({ _highlightResult }) {
            return `<span><i class="fa fa-search"></i>${_highlightResult.name_th.value}</span>`;
          },
        },
      },
      {
        debounce: debounceTime,
        source: autocomplete.sources.hits(indexes.routes, { hitsPerPage: hitCount }),
        displayKey: 'name_th',
        templates: {
          suggestion({ _highlightResult }) {
            return `<span><i class="fa fa-search"></i>${_highlightResult.name_th.value}</span>`;
          },
        },
      },
      {
        debounce: debounceTime,
        source: autocomplete.sources.hits(indexes.collections, { hitsPerPage: hitCount }),
        displayKey: 'name_th',
        templates: {
          suggestion({ _highlightResult }) {
            return `<span><i class="fa fa-search"></i>${_highlightResult.name_th.value}</span>`;
          },
        },
      },
    ];
  }
}

export default {
  search: null,
  attachSearchEngine(inputElem) {
    if (inputElem !== null && inputElem.tagName === 'INPUT') {
      const algoliaEngine = new AlgoliaEngine();
      const autoCompleteTarget = algoliaEngine.autoCompleteTarget();
      if (autoCompleteTarget === null || autoCompleteTarget === 'undefined') return;
      const autoCompleteOption = algoliaEngine.option;
      this.search = autocomplete(inputElem, autoCompleteOption, autoCompleteTarget);
      this.search.on('autocomplete:selected', (e) => {
        e.target.closest('.input-group').classList.add('selected');
        e.target.closest('form').submit();
      });
    }
  },
  destroy() {
    this.search.autocomplete.destroy();
  },
};
