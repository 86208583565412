'strict mode';

function submitCandidateForm(e) {
  e.preventDefault();
  const elem = e.target;
  const url = elem.getAttribute('action');
  if (elem.querySelector("#candidate_knowing_channel_other[type='text']").value !== '') {
    document.getElementById('candidate_knowing_channel_other').value = elem.querySelector("#candidate_knowing_channel_other[type='text']").value;
  }
  const formData = new FormData(elem);
  const request = new XMLHttpRequest();
  elem.classList.add('slide-up');
  document.querySelector('.title').classList.add('hide');
  document.querySelector('.step-2').classList.add('show');
  document.querySelector('.navbar .position-absolute').classList.add('d-none');
  document.querySelector('.navbar .position-absolute').classList.remove('d-flex');
  request.open('POST', url, true);
  request.send(formData);
  request.onload = function () {
    document.querySelector('.step-3').classList.add('show');
    document.querySelector('.step-2').classList.remove('show');
  };
}

const validation = {
  eachInput: (e) => {
    const input = e.target === undefined ? e : e.target;
    if (input === null || input.value === null) return;
    const form = input.closest('form');
    const submitBtn = form.querySelector(".btn.btn-green[type='submit']");
    const inputType = input.getAttribute('id').split('candidate_')[1];
    let isValid = false;
    switch (inputType) {
      case 'email':
        if (is.email(input.value)) isValid = true;
        break;
      case 'phone':
        if (/^[0-9]{2,3}?[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/ig.test(input.value)) isValid = true;
        break;
      default: if (input.value.length !== 0) isValid = true;
    }
    if (isValid) {
      input.classList.add('valid');
      input.classList.remove('is-invalid');
    } else {
      input.classList.add('is-invalid');
      input.classList.remove('valid');
    }
    validation.form(form, submitBtn);
  },
  allInput: (inputArr) => {
    inputArr.forEach((input) => {
      validation.eachInput(input);
    });
  },
  form: (form, submitBtn) => {
    const formIsValid = form.querySelectorAll('[required].valid').length >= 5;
    if (formIsValid) {
      submitBtn.classList.remove('disabled');
    } else {
      submitBtn.classList.add('disabled');
    }
    submitBtn.disabled = !formIsValid;
  },
};

function fileInputOnchange(e) {
  const input = e.target;
  let cloneInput = input.parentNode.querySelector('.clone');
  if (input.files[0] === undefined && cloneInput !== null) {
    input.files = cloneInput.files;
    return;
  } else if (cloneInput === null) {
    cloneInput = input.cloneNode(true);
    cloneInput.removeAttribute('id');
    cloneInput.removeAttribute('name');
    cloneInput.classList.add('clone');
    input.parentNode.appendChild(cloneInput);
  }
  const isNotCreate = input.parentNode.querySelector('div.filename') === null;
  const div = isNotCreate ? document.createElement('div') : input.parentNode.querySelector('div.filename');
  div.textContent = input.files[0].name;
  div.style.maxWidth = `${input.parentNode.querySelector('label').offsetWidth}px`;
  if (isNotCreate) {
    div.classList.add('filename');
    input.parentNode.insertBefore(div, input.closest('label'));
  }
  const span = document.createElement('span');
  span.classList.add('fa', 'fa-times');
  span.addEventListener('click', (event) => {
    const closeBtn = event.target;
    const fileInput = closeBtn.closest('.file-upload').querySelector('input');
    closeBtn.parentNode.parentNode.removeChild(closeBtn.closest('.file-upload').querySelector('.clone'));
    fileInput.value = '';
    closeBtn.parentNode.parentNode.removeChild(closeBtn.parentNode);
    validation.eachInput(fileInput);
  });
  div.appendChild(span);
}

function reInitFilename() {
  const form = document.getElementById('new_candidate');
  const fileDiv = form.querySelector('.file-upload .filename');
  const fileLabel = form.querySelector('.file-upload label');
  if (fileDiv !== null) {
    fileDiv.style.maxWidth = `${fileLabel.offsetWidth}px`;
  }
}

export default {
  turbolinksLoad() {
    const elem = document.getElementById('new_candidate');
    if (elem !== null) {
      elem.addEventListener('submit', (e) => {
        submitCandidateForm(e);
      });
      elem.querySelector('[type="file"]').addEventListener('change', (e) => {
        fileInputOnchange(e);
      });
      elem.querySelectorAll('[required]').forEach((input) => {
        input.addEventListener('change', (e) => {
          validation.eachInput(e);
        });
      });
      elem.querySelector(".btn.btn-green[type='submit']").addEventListener('click', () => {
        elem.querySelector('.clone').parentNode.removeChild(elem.querySelector('.clone'));
        const inputArr = elem.querySelectorAll('[required]');
        validation.allInput(inputArr);
      });
      window.addEventListener('resize', reInitFilename);
    }
  },
  turbolinksBeforeCache() {
    window.removeEventListener('resize', reInitFilename);
  },
};
