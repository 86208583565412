'strict mode';

import moment from 'moment';

const privateFormValidation = {
  validateByType: function (input) {
    if (is.undefined(input)) {
      return false;
    }

    let validateResult;
    if (input.is('#private_group_order_name, #private_group_order_budget, #start_date')) {
      validateResult = this.validateTextField(input);
    } else if (input.is('#private_group_order_email')) {
      validateResult = this.validateEmail(input);
    } else if (input.is('#private_group_order_phone')) {
      validateResult = this.validatePhone(input);
    }

    if (validateResult) {
      this.setValid(input);
    } else {
      this.setInvalid(input);
    }

    return true;
  },
  validateTextField: function (input) {
    return is.not.empty(input.val());
  },
  validateEmail: function (input) {
    return is.email(input.val());
  },
  validatePhone: function (input) {
    const regex = /^[0-9]{2,3}?[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/ig;
    return regex.test(input.val());
  },
  setValid: function (input) {
    input.removeClass('is-invalid').addClass('is-valid');
  },
  setInvalid: function (input) {
    input.removeClass('is-valid').addClass('is-invalid');
  },
  validateAll: function (form) {
    if (is.undefined(form)) {
      return false;
    }

    form.find('input[required]').each(function () {
      const input = $(this);
      const result = privateFormValidation.validateTextField(input);

      if (result) {
        privateFormValidation.setValid(input);
      } else {
        privateFormValidation.setInvalid(input);
      }
    });

    return $('input[required].is-valid').length > 4;
  },
};

export default {
  turbolinksLoad() {
    if ($('body').is('.pages.show.private-tour')) {
      const privateOrderForm = $('#new_private_group_order');
      privateOrderForm.find(':input[required]').on('change keyup', function () {
        const input = $(this);
        privateFormValidation.validateByType(input);
      });

      const datepickerElement = $('#start_date');
      if (datepickerElement.length) {
        datepickerElement.datepicker({
          language: 'th',
          templates: {
            leftArrow: '&#60;',
            rightArrow: '&#62;',
          },
          maxViewMode: 1,
          format: 'yyyy-mm-dd',
          startDate: `${moment()}`,
        });

        datepickerElement.datepicker().on('hide', function () {
          const date = datepickerElement.val();
          datepickerElement.next('input:hidden').val(date);
        });
      }

      privateOrderForm.find("input[type='submit']").click(function (e) {
        e.preventDefault();
        const checkAll = privateFormValidation.validateAll(privateOrderForm);
        if (checkAll) {
          privateOrderForm.submit();
        } else {
          $(':input.invalid:first').focus();
        }
      });
    }
  },
};
