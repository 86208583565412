'strict mode';

export default {
  turbolinksLoad() {
    $('#scroll-to-step-booking').click(function () {
      $('html, body').animate({
        scrollTop: $('#mobile-booking-section').offset().top - 70,
      }, 1000);
    });
  },
};
