'strict mode';

// Noted, features deplicated on current version, no more bookmark tabbar
// Todo fully deplicated bookmarkTabbar

import userGuidelines from './user-guidelines';
import Toast from './toast';

const bookmarkTabbar = {
  desktopBreakpoint: 992,
  validState: {
    isLoggedIn: () => $('a.js-user-login-flag').length > 0,
    isSearchPage: () => $('.tours.searches, .collections.show, .long_tails.show').length > 0,
    isNotExisted: () => $('.js-top-bookmark-links').length > 0,
    isNotDesktop: () => $(window).innerWidth() < bookmarkTabbar.desktopBreakpoint,
    isValid: function () {
      return this.isLoggedIn() && this.isNotDesktop() && this.isSearchPage();
    },
  },
  checkStateThenCreate: () => {
    if (bookmarkTabbar.validState.isNotExisted() && bookmarkTabbar.validState.isValid()) {
      bookmarkTabbar.create();
      $('.btn-go-to-top').removeClass('locked-down');
    } else if (bookmarkTabbar.validState.isSearchPage()) {
      $('.btn-go-to-top').addClass('locked-down');
    }
  },
  insertContext: (elem, bookmark) => {
    const bookmarkSize = +bookmark.attr('data-bookmark');
    const btnStates = bookmarkSize < 2 ? 'disabled' : '';
    if (bookmarkSize < 2) {
      const span = $('<span/>', { class: 'tabbar__context--text text-truncate' });
      span.append('กด ');
      bookmark.clone().find('.color').appendTo(span);
      span.append(' สองอันขึ้นไป').appendTo(elem);
    } else {
      bookmark.clone().removeClass('js-top-bookmark-links').addClass('js-tabbar-bookmark-links tabbar__context--icon').appendTo(elem);
      $(`<span class='tabbar__context--text'>ถูกใจ ${bookmarkSize} แพ็คเกจ</span>`).appendTo(elem);
    }
    $(`<a href='/users/compare' class='btn tabbar__context--btn btn-sm btn-rounded btn-orange ml-auto ${btnStates}' >เปรียบเทียบ</a>`).appendTo(elem);
  },
  create: () => {
    const bookmark = $('.js-top-bookmark-links:first');
    const div = $('<div/>', { class: 'tabbar fixed-bottom compare-bar bg-white d-flex' });
    bookmarkTabbar.insertContext(div, bookmark);
    div.appendTo('body');
  },
  replace: () => {
    const div = $('body').find('.tabbar.fixed-bottom').html('');
    const bookmark = $('.js-top-bookmark-links:first');
    bookmarkTabbar.insertContext(div, bookmark);
  },
  destroy: () => {
    const tabbar = $('body').find('.tabbar.fixed-bottom.compare-bar');
    tabbar.remove();
  },
};

function setTabbarActiveBookmark(state) {
  const tabbar = document.querySelector('.tabbar.fixed-bottom.call-to-action .call-to-action__action-bookmark');
  if (tabbar !== null) {
    state = state || false;
    if (state) {
      tabbar.classList.add('active');
    } else {
      tabbar.classList.remove('active');
    }
  }
}

function notified() {
  if (!bookmarkTabbar.validState.isValid()) return;
  const overlay = document.createElement('div');
  const tabbar = document.body.querySelector('.tabbar.fixed-bottom');

  document.body.dataset.scroll = 'down';
  document.body.classList.add('visible-overlay');
  overlay.classList.add('overlay', 'animated');
  overlay.setAttribute('style', 'z-index: 1100');
  tabbar.setAttribute('style', 'z-index: 1150');

  overlay.addEventListener('click', function () {
    tabbar.querySelector('.tabbar__context--btn').classList.remove('animated');
    overlay.classList.add('hiding');
    setTimeout(function () {
      document.body.classList.remove('visible-overlay');
      document.body.removeChild(overlay);
      tabbar.removeAttribute('style');
    }, 250);
  });

  document.body.appendChild(overlay);
  setTimeout(function () {
    tabbar.querySelector('.tabbar__context--btn').classList.add('animated');
  }, 250);
}

function addBookmarkBtn() {
  $('.btn-add-bookmark').unbind('click').on('click', (e) => {
    e.preventDefault();
    const $link = $(e.target).is('i') || $(e.target).is('span') ? $(e.target).parent() : $(e.target);
    const tourID = $link.data('tour-id');
    $.post(`/tours/${tourID}/bookmark`, (data) => {
      $('.js-top-bookmark-links:visible').addClass('changing up')
        .delay(350)
        .queue(function (next) {
          $(this).attr('data-bookmark', data.bookmark_size);
          $(this).removeClass('changing up');
          if (bookmarkTabbar.validState.isValid()) {
            bookmarkTabbar.replace();
            if (data.bookmark_size === 10) notified();
          }
          next();
        });
      Toast.add('createBookmarkSuccess');
    }).done(() => {
      Turbolinks.clearCache();
    });
    $link.parent().addClass('bookmark-added');
    setTabbarActiveBookmark(true);
  });
}

function removeBookmarkBtn() {
  $('.btn-remove-bookmark').unbind('click').on('click', (e) => {
    e.preventDefault();
    const $link = $(e.target).is('i') || $(e.target).is('span') ? $(e.target).parent() : $(e.target);
    const tourID = $link.data('tour-id');
    $.ajax({
      url: `/tours/${tourID}/bookmark`,
      type: 'DELETE',
    }).done((data) => {
      Turbolinks.clearCache();
      $('.js-top-bookmark-links:visible').addClass('changing down')
        .delay(300)
        .queue(function (next) {
          $(this).attr('data-bookmark', data.bookmark_size);
          $(this).removeClass('changing down');
          if (bookmarkTabbar.validState.isValid()) bookmarkTabbar.replace();
          next();
        });
      Toast.add('destroyBookmarkSuccess');
    });
    $link.parent().removeClass('bookmark-added');
    setTabbarActiveBookmark();
  });
}

function setFacebookOauthUrl(facebookBtn, tourId) {
  const url = new URL(facebookBtn.dataset.url);
  url.searchParams.set('bookmark[tour_id]', tourId);

  const redirectTo = new URL(document.URL);
  const scrollTop = document.documentElement.scrollTop.toFixed(2);
  redirectTo.searchParams.set('scroll', scrollTop);
  url.searchParams.set('redirect_to', redirectTo);

  facebookBtn.setAttribute('href', url);
}

function setFormActionUrl(form, tourId) {
  const url = new URL(form.getAttribute('action'));
  url.searchParams.set('bookmark[tour_id]', tourId);
  form.setAttribute('action', url.href);
}

function setSignInModal(tourId) {
  const signInForm = document.getElementById('sign_in_modal_form');
  setFormActionUrl(signInForm, tourId);

  const signInModal = document.getElementById('sign_in_modal');
  const facebookOauthBtn = signInModal.querySelector('.btn-facebook-oauth');
  setFacebookOauthUrl(facebookOauthBtn, tourId);
}

function setSignUpModal(tourId) {
  const signUpForm = document.getElementById('sign_up_modal_form');
  setFormActionUrl(signUpForm, tourId);

  const signUpModal = document.getElementById('sign_up_modal');
  const facebookOauthBtn = signUpModal.querySelector('.btn-facebook-oauth');
  setFacebookOauthUrl(facebookOauthBtn, tourId);
}

function onBtnBookmarkDummyClick() {
  document.querySelectorAll('.btn-bookmark-dummy').forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const { tourId } = e.currentTarget.dataset;
      setSignInModal(tourId);
      setSignUpModal(tourId);
    });
  });
}

function initializeTurbolinksLoad() {
  if ($('.js-bookmark-links').length > 0) {
    addBookmarkBtn();
    removeBookmarkBtn();
    onBtnBookmarkDummyClick();
  }
}

export default {
  turbolinksBeforeCache() {
    bookmarkTabbar.destroy();
  },
  turbolinksLoad() {
    initializeTurbolinksLoad();
    $(document).ajaxComplete(() => {
      initializeTurbolinksLoad();
    });

    /*
     *
     * initialize and create element for collection and searches page
     *
     */
    if ($('.tours.searches, .collections.show, .long_tails.show').length > 0) {
      bookmarkTabbar.checkStateThenCreate();
      userGuidelines.checkCookies();

      window.addEventListener('resize', () => {
        if ($(window).innerWidth() > bookmarkTabbar.desktopBreakpoint) {
          bookmarkTabbar.destroy();
        } else {
          bookmarkTabbar.checkStateThenCreate();
        }
      });
    }
  },
};
