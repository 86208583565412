'strict mode';

export default {
  turbolinksLoad() {
    $('a[data-toggle="dropdown"]').on('click', function () {
      if (document.documentElement.clientWidth < 992) { return; }

      const $el = $(this);
      if ($el.is(':hover')) {
        if ($el.length && $el.attr('href') && $el.attr('href') !== '#') {
          window.location.href = $el.attr('href');
        }
      }
    });
  },
};
