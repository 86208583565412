import { SMALL_DEVICES_WIDTH } from '../../shared/constants';

function close() {
  // close drop down suggestion
  const dropDownElem = document.querySelector('.ajax-search-result');
  if (dropDownElem !== null) {
    dropDownElem.innerHTML = '';
    dropDownElem.classList.add('empty');
  }
}

function setActiveResult(list, pointer) {
  const results = list.querySelectorAll('p');
  list.dataset.pointer = pointer;
  results.forEach(function (elem) {
    elem.classList.remove('active');
  });
  results[pointer].classList.add('active');
}

function replaceLiveSearchInput(e) {
  // replace input with drop down suggestion
  const isMobile = window.innerWidth < SMALL_DEVICES_WIDTH;
  const isMouseEvent = (e instanceof MouseEvent);
  const targetForm = isMobile ? '.search-box.modal.modal-cloned form' : '.search-box.modal:not(.modal-cloned) form';
  const form = document.querySelector(targetForm);
  const elem = isMouseEvent ? e.target : e;
  const input = form.querySelector('#search_input');
  const optionalInput = form.querySelector('[title=optional_input]');
  if (elem.dataset.type === 'route_groups') {
    const checkboxGroup = document.createElement('div');
    elem.dataset.optionalValue.split(',').forEach(function (id) {
      const checkbox = document.createElement('input');
      checkbox.setAttribute('title', 'optional_input');
      checkbox.setAttribute('type', 'checkbox');
      checkbox.setAttribute('checked', true);
      checkbox.setAttribute('value', id);
      checkboxGroup.appendChild(checkbox);
    });
    checkboxGroup.classList.add('d-none');
    optionalInput.replaceWith(checkboxGroup);
  } else {
    optionalInput.value = elem.dataset.optionalValue;
  }
  if (!isMouseEvent) input.classList.add('filled-with-enter');
  input.value = elem.innerHTML;
  input.dataset.type = elem.dataset.type;
  close();
}

function resultTemplate(response) {
  // transfer json response into element with event: hover, click
  const data = JSON.parse(response);
  const elem = document.createElement('ul');
  window.data = data;
  let i = 0;
  for (const type in data) {
    for (const item in data[type]) {
      const tag = document.createElement('p');
      const text = document.createTextNode(`${data[type][item].name_th}`);
      tag.dataset.index = i;
      tag.dataset.type = type;
      if (type === 'route_groups') {
        let routeIds = '';
        for (const route in data[type][item].routes) {
          routeIds += `${data[type][item].routes[route].name_th},`;
        }
        // prevent add additional , on last one
        routeIds = routeIds.substring(0, routeIds.length - 1);
        tag.dataset.optionalValue = routeIds;
      } else {
        tag.dataset.optionalValue = data[type][item].name_th;
      }
      tag.classList.add('list-group-item', 'list-group-item-action');
      tag.appendChild(text);
      tag.addEventListener('click', replaceLiveSearchInput, false);
      ['mouseenter', 'mouseleave'].forEach(function (event) {
        tag.addEventListener(`${event}`, function () {
          setActiveResult(this.closest('.list-group'), this.dataset.index);
        });
      });
      elem.appendChild(tag);
      i += 1;
    }
  }
  elem.classList.add('list-group', 'ajax-search-result');
  return elem;
}

function getResult(e) {
  // get result when input has value and had new text entered
  const input = e.target;
  const str = input.value;
  const searchData = input.dataset.searchText || '';
  const filledWithEnter = input.classList.contains('filled-with-enter');
  const navBarHeight = document.querySelector('.navbar.navbar--primary').getBoundingClientRect().height;

  // dropdown elem offset
  const isMobile = window.innerWidth < SMALL_DEVICES_WIDTH;
  const elemRender = input.getBoundingClientRect();
  const offsetLeft = Math.round(elemRender.left);
  const inputWidth = Math.round(elemRender.width);
  let offsetTop = window.pageYOffset;
  if (isMobile) {
    offsetTop += Math.round((elemRender.top + elemRender.height) - navBarHeight);
  } else {
    offsetTop += Math.round(elemRender.top + elemRender.height);
  }

  const resultExisted = document.querySelector('.ajax-search-result') !== null;

  if (input !== null) {
    if (str.length === 0 || filledWithEnter) {
      close();
      input.dataset.searchText = str;
      input.classList.remove('filled-with-enter');
      return;
    } else if (!filledWithEnter) {
      // reset optional input
      const optionalInput = document.createElement('input');
      optionalInput.type = 'hidden';
      optionalInput.title = 'optional_input';
      optionalInput.disabled = true;
      input.nextElementSibling.replaceWith(optionalInput);
    }
    if (searchData !== str) {
      // Todo done prefetch and cache
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          const replaceContent = resultTemplate(this.response);
          if (replaceContent.querySelector('p') !== null) input.dataset.type = '';
          replaceContent.setAttribute('style', `top: ${offsetTop}px; left: ${offsetLeft}px; width: ${inputWidth}px;`);
          if (resultExisted) {
            document.querySelector('.ajax-search-result').replaceWith(replaceContent);
            document.querySelector('.ajax-search-result').classList.remove('empty');
          } else {
            document.body.appendChild(replaceContent);
          }
        }
      };
      xmlhttp.open('GET', `/tours/search/suggestion/${str}`, true);
      xmlhttp.send();
      input.dataset.searchText = str;
    }
  }
}

function resultSelect(e) {
  // select by arrow up/down/enter and escape for close drop down suggestion
  const input = e.target;
  const functionKey = [38, 40, 13, 27];
  const isFunctionKey = functionKey.includes(e.keyCode);
  if (isFunctionKey) {
    const list = document.querySelector('.ajax-search-result');
    const checkResultEmpty = list === null || list.innerHTML === '' ? true : list.classList.contains('empty');
    if (!checkResultEmpty) {
      const results = list.querySelectorAll('p');
      const min = 0;
      const max = results.length - 1;
      let pointer = list.dataset.pointer === undefined ? '' : +list.dataset.pointer;
      if (e.keyCode === 38) {
        if (pointer === '' || pointer <= min) {
          list.dataset.pointer = results.length - 1;
          pointer = results.length - 1;
        } else {
          pointer -= 1;
        }
        setActiveResult(list, pointer);
      } else if (e.keyCode === 40) {
        if (pointer === '' || pointer >= max) {
          list.dataset.pointer = 0;
          pointer = 0;
        } else {
          pointer += 1;
        }
        setActiveResult(list, pointer);
      } else if (e.keyCode === 13) {
        if (pointer !== '') {
          e.preventDefault();
          replaceLiveSearchInput(results[pointer]);
        }
      } else if (e.keyCode === 27) {
        input.value = '';
      }
    }
  }
}

function attachEventList(e) {
  // add input/ change / keydown events to input
  const input = e.target;
  ['input', 'change'].forEach((event) => {
    input.addEventListener(event, getResult, false);
  });
  input.addEventListener('keydown', resultSelect, false);
}

export default {
  attachEventList,
  resultSelect,
  getResult,
  resultTemplate,
  replaceLiveSearchInput,
  setActiveResult,
  close,
};
