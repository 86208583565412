'strict mode';

/* eslint-disable import/first,no-new,max-len */
/* eslint-disable-next-line no-new */

import clipboard from 'clipboard-polyfill';
import stickybits from 'stickybits';
import DailyItinerary from './daily-itinerary';
import {
  animateToOffset,
  greatherThanOrEqualIpadProWidth,
  minusOffSetDueToTourNavHeight,
} from '../../shared/utilities';
import { ANIMATE_DURATION, TOUR_NAV_HEIGHT, SMALL_DEVICES_WIDTH } from '../../shared/constants';
import goToTopButtonInitializer from './top-btn';

function expandPeriodTable(target) {
  if (target.events.length > 0 && 'targetId' in target.events[0]) {
    const $targetElement = $(target.events[0].targetId);
    $targetElement.find('.btn-table-cell').first().click();
  }
}

function navigateMonthPeriods(moment) {
  $('.month-periods').find('.btn-month-period').removeClass('active');
  $('.btn-month-period')
    .filter(function () {
      return +$(this).data('month') === moment.month();
    })
    .addClass('active');
}

function journeyModelInitializer() {
  // Activate journey-preview Modal
  const imgExpander = $('.tours.show .image-expander');
  [
    imgExpander.parents('.utility-caption-text'),
    imgExpander.parents('.utility-caption-text').prev('.utility-overlay'),
  ].forEach((elem) => {
    elem.on('click', () => {
      $('#journey-preview').modal({ show: true });
    });
  });

  const viewModes = {
    'box-icon': ['col-12', 'col-6'],
    'roll-icon': ['col-6', 'col-12'],
  };

  $('.box-icon, .roll-icon').on('click', function (event) {
    event.preventDefault();
    const mode = $(event.target).data('type');
    if (mode === 'box-icon') {
      $('.box-icon-inactive').hide();
      $('.box-icon-active').show();
      $('.roll-icon-inactive').show();
      $('.roll-icon-active').hide();
    } else {
      $('.box-icon-inactive').show();
      $('.box-icon-active').hide();
      $('.roll-icon-inactive').hide();
      $('.roll-icon-active').show();
    }
    $('.annotation_tour')
      .removeClass(viewModes[mode][0])
      .addClass(viewModes[mode][1]);
  });
}

function clndrTemplate() {
  return "<div class='border border-left-0 border-right-0 py-4 row clndr-controls no-gutters bg-white'>" +
      "<div class='col-2 text-center clndr-control-button text-center clndr-previous-button'>" +
        "<i class='fa fa-chevron-left' aria-hidden='true'></i>" +
      '</div>' +
      "<div class='col-8 month text-center'><%= month %> <%= year %></div>" +
      "<div class='col-2 clndr-control-button right-align text-center clndr-next-button'>" +
        "<i class='fa fa-chevron-right' aria-hidden='true'></i>" +
      '</div>' +
    '</div>' +
    "<table class='clndr-table' border='0' cellspacing='0' cellpadding='0'>" +
      '<thead>' +
        "<tr class='header-days'>" +
        '<% for(var i = 0; i < daysOfTheWeek.length; i++) { %>' +
            "<td class='header-day' width='14.28571%'><%= daysOfTheWeek[i] %></td>" +
        '<% } %>' +
        '</tr>' +
      '</thead>' +
      '<tbody>' +
      '<% for(var i = 0; i < numberOfRows; i++){ %>' +
        '<tr>' +
        '<% for(var j = 0; j < 7; j++){ %>' +
        '<% var d = j + i * 7; %>' +
          '<% if (days[d].events.length) { %>' +
              '<% _.each(days[d].events, function(event){ %>' +
              "<% days[d].available = event.available ? 'available' : 'unavailable' %>" +
              "<td class='<%= days[d].classes %> <%= days[d].available %>' width='14.28571%'>" +
                "<div class='day-contents'><%= days[d].day %></div>" +
                "<div class='event-pricing'><%= event.price %></div>" +
              '</td>' +
            '<% }) %>' +
          '<% } else { %>' +
            "<td class='<%= days[d].classes %>' width='14.28571%'>" +
              "<div class='day-contents'><%= days[d].day %></div>" +
            '</td>' +
          '<% } %>' +
        '<% } %>' +
        '</tr>' +
      '<% } %>' +
      '</tbody>' +
    '</table>';
}

function periodInitializer() {
  // Scrollspy
  const target = $('body');
  target.scrollspy({ target: '#tour-nav', offset: 56 });

  const eventsList = [];
  target.find('.period_date_iso').each(function () {
    const targetID = $(this).data('id');
    const availability = $(this).data('available');
    eventsList.push({
      price: $(this).data('price').split('.')[0],
      date: $(this).data('date'),
      targetId: `#period_${targetID}_header`,
      available: availability,
    });
  });

  let animateMode = false;
  const calendarElem = $('.calendar');
  const $periodCalendar = (calendarElem.length > 0) ?
    calendarElem.clndr({
      showAdjacentMonths: false,
      daysOfTheWeek: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
      events: eventsList,
      template: clndrTemplate(),
      clickEvents: {
        click: function (clickedDate) {
          animateMode = true;
          expandPeriodTable(clickedDate);
        },
        onMonthChange: function (moment) { navigateMonthPeriods(moment); },
      },
      constraints: {
        startDate: eventsList[0].date,
        endDate: eventsList[eventsList.length - 1].date,
      },
    }) : false;

  $('.btn-month-period').on('click', function (event) {
    event.preventDefault();
    $periodCalendar.setMonth($(this).data('month'));
    $periodCalendar.setYear($(this).data('year'));
    $('.btn-month-period').removeClass('active');
    $(this).addClass('active');
  });

  /*
   *
   * Add/Remove Borders Of Seleted Daily Iternarary Row
   *
   */

  const holidayPeriod = $('.holiday_period');
  holidayPeriod.on('show.bs.collapse', (e) => {
    const $row = $(e.target);
    $row.prev().find('.btn').text('ย่อ');
    $row.prev().addClass('border-highlight');

    let $topElement = {};
    if ($row.prev().prev().length === 0) {
      $topElement = $('.thead-light tr');
    } else {
      $topElement = $row.prev().prev().prev().prev();
    }

    $topElement.addClass('highlight-bottom');
  });

  holidayPeriod.on('hidden.bs.collapse', (e) => {
    const $row = $(e.target);
    $row.prev().find('.btn').text('เลือก');
    $row.prev().removeClass('border-highlight');

    let $topElement = {};
    if ($row.prev().prev().length === 0) {
      $topElement = $('.thead-light tr');
    } else {
      $topElement = $row.prev().prev().prev().prev();
    }
    $topElement.removeClass('highlight-bottom');
  });

  $('.holiday_period').on('shown.bs.collapse', function (e) {
    if (animateMode) {
      const offset = $(e.target).prev().offset().top;
      const topMargin = 10;
      animateToOffset(
        minusOffSetDueToTourNavHeight(offset) - topMargin,
        ANIMATE_DURATION,
      );
      animateMode = false;
    }
  });
}

function conditionController() {
  $('#nav-condition .nav-link').on('click', function (event) {
    event.preventDefault();
    $('#nav-condition').find('.link-activated').addClass('d-none');
    $('#nav-condition').find('.link-deactivated').removeClass('d-none');
    $(this).find('.link-activated').removeClass('d-none');
    $(this).find('.link-deactivated').addClass('d-none');
  });

  $('.link-expander, .link-collapse').on('click', function () {
    $(this).prev().toggleClass('tab-expand');
    $('.tab-pane').toggleClass('tab-expand');
    $('.link-expander').toggleClass('d-none');
    $('.link-collapse').toggleClass('d-none');

    if ($(this).hasClass('link-collapse')) {
      const offset = $('#conditions').offset().top;
      animateToOffset(minusOffSetDueToTourNavHeight(offset), ANIMATE_DURATION);
    }
  });
}

function animateToBookSection() {
  const target = $('#periods_table');
  const smallestDevice = $(window).innerWidth() < SMALL_DEVICES_WIDTH;
  const scrolledOverTopOfPeriodTable = target.offset().top > $(window).scrollTop();

  let offset = target.offset().top - 10;
  if (smallestDevice && scrolledOverTopOfPeriodTable) {
    offset += 60;
  }
  animateToOffset(minusOffSetDueToTourNavHeight(offset), ANIMATE_DURATION);
}

function channelsInitializer() {
  $('.booking-web').on('click', (e) => {
    e.preventDefault();
    animateToBookSection();
  });
  $('.btn-copy').on('click', () => {
    const tourID = $('.booking-tour-no').first().text();
    clipboard.writeText(tourID);
  });
}

function getBookingChannelTopOffset() {
  const $bookingChannels = $('.overview-container .desktop-summary .booking-channels--large-device');
  return $bookingChannels.offset().top + $bookingChannels.outerHeight(true);
}

function stickysInitializer() {
  stickybits('.stickybits');

  const $stickyTourSummary = $('.sticky-tour-summary');
  if ($stickyTourSummary.length > 0) {
    const stickybitTopMargin = 30;
    stickybits('.sticky-tour-summary', {
      stickyBitStickyOffset: TOUR_NAV_HEIGHT + stickybitTopMargin,
    });
    $(window).on('scroll', () => {
      if ($(window).scrollTop() >= getBookingChannelTopOffset() &&
        greatherThanOrEqualIpadProWidth()) {
        $stickyTourSummary.show();
      } else {
        $stickyTourSummary.hide();
      }
    });
  }

  $('#tour-nav .nav-link').on('click', (e) => {
    e.preventDefault();
    const animatedTarget = $(e.target).data('target');
    const offset = $(animatedTarget).offset().top;
    animateToOffset(minusOffSetDueToTourNavHeight(offset), ANIMATE_DURATION);
  });
}

const callToActionBar = {
  listening: () => {
    const modalableTabbars = [
      { action: 'call', modal: 'phone' },
      { action: 'line', modal: 'line' }
    ]

    modalableTabbars.forEach((modalableTabbar) => {
      $('.call-to-action__action-' + modalableTabbar.action).on('click', () => {
        $('.modal-booking-' + modalableTabbar.modal).modal('toggle');
      });
    });

    $('.call-to-action__action-pdf-download').on('click', (e) => {
      const element = e.target;
      window.open(element.dataset.url, '_blank');
    });

    $('.call-to-action__action-bookmark').on('click', () => {
      $('.summary-detail-small-devices').find(".js-bookmark-links a[class$='-bookmark']:visible").click();
    });

    $('.call-to-action__action-reserve').on('click', () => {
      animateToBookSection();
    });
  },
  destroy: () => {
    $('body').find('.tabbar.fixed-bottom.call-to-action').remove();
  },
  attachWindowScrollEvent: () => {
    let position = 0;
    const safePoint = $('.booking-channels');
    if (safePoint.length <= 0) return false;

    const safeZone = safePoint.offset().top + safePoint.height();
    $('body').addClass('js-search-scroll-controlled');
    window.addEventListener('scroll', () => {
      const scrolledPosition = $(window).scrollTop();
      const trackingTarget = $('body');
      if (scrolledPosition > position && scrolledPosition > safeZone) {
        trackingTarget.attr('data-scroll', 'down');
        if (scrolledPosition > safeZone) {
          $('.tabbar.fixed-bottom.call-to-action').addClass('slide-in');
        }
      } else {
        trackingTarget.attr('data-scroll', 'up');
        $('.tabbar.fixed-bottom.call-to-action').removeClass('slide-in');
      }
      position = scrolledPosition;
    });
  },
};

export default {
  turbolinksBeforeCache() {
    $(window).off('scroll');
    callToActionBar.destroy();
  },
  turbolinksLoad() {
    if (($('.tours.show').length > 0) && ($('.tours.searches').length === 0)) {
      journeyModelInitializer();
      periodInitializer();
      new DailyItinerary();
      conditionController();
      channelsInitializer();
      stickysInitializer();
      goToTopButtonInitializer($('.btn-go-to-top'));
      callToActionBar.attachWindowScrollEvent();
      callToActionBar.listening();
    }
  },
};
