'strict mode';

import DeviceOption from '../modules/deviceOption';

const stickyMobileBanner = {
  attachEvents: (elem) => {
    const closeBtn = elem.querySelector('.sticky-mobile-banner__inner__close-container .icon');
    closeBtn.addEventListener('click', (e) => { e.preventDefault(); stickyMobileBanner.close(elem); });
  },
  captureScroll: (elem) => {
    elem.classList.add('js-captured');
    window.addEventListener('scroll', _.throttle(stickyMobileBanner.checkScrollHeight, 300));
  },
  destroyCaptureScroll: () => {
    window.removeEventListener('scroll', _.throttle(stickyMobileBanner.checkScrollHeight, 300));
  },
  checkScrollHeight: () => {
    if (document.querySelector('.sticky-mobile-banner') === null) return;
    const deviceOption = new DeviceOption();
    const breakPointID = deviceOption.isMobileDevice ? 'long_holidays' : 'lifeStyle';
    const stickyBanner = document.querySelector('.sticky-mobile-banner');
    const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop;
    const heightToHide = document.getElementById(breakPointID).offsetTop;
    if (scrollPos > heightToHide) {
      stickyMobileBanner.hide(stickyBanner);
    } else {
      stickyMobileBanner.show(stickyBanner);
    }
  },
  hide: (elem) => {
    elem.classList.add('hided');
    setTimeout(() => {
      elem.classList.add('d-none');
    }, 250);
  },
  close: (elem) => {
    elem.classList.add('is-closed');
    setTimeout(() => {
      elem.classList.add('d-none');
    }, 250);
  },
  show: (elem) => {
    elem.classList.remove('d-none');
    setTimeout(() => {
      elem.classList.remove('hided');
    }, 250);
  },
};

export default {
  turbolinksBeforeCache() {
    stickyMobileBanner.destroyCaptureScroll();
  },
  turbolinksLoad() {
    if (document.querySelector('.sticky-mobile-banner') !== null) {
      const elem = document.querySelector('.sticky-mobile-banner');
      stickyMobileBanner.captureScroll(elem);
      stickyMobileBanner.attachEvents(elem);
    }
  },
};
