'strict mode';

// todo improve conditional logic on open() and bindClickEvent()

function safeJsonParse(json) {
  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    parsed = null;
  }
  return parsed;
}

const summaryDetails = {
  setActive: (elem) => {
    elem.closest('.summary-details__action-wrapper').classList.add('active');
  },
  setInActive: (elem) => {
    elem.closest('.summary-details__action-wrapper').classList.remove('active');
  },
};

function disposeAllIfNotClickAble(e) {
  const elem = e.target;
  if (elem.closest('.share-tooltips') === null) {
    const openedToolTips = document.querySelectorAll('.js-share-tooltips');
    if (openedToolTips.length > 0) {
      openedToolTips.forEach((node) => {
        const tooltipContainer = node.closest('.share-tooltips');
        const isOnToursPage = node.closest('.summary-details__action-wrapper') !== null;
        if (isOnToursPage) summaryDetails.setInActive(node);
        tooltipContainer.removeChild(node);
        tooltipContainer.classList.remove('active');
      });
    }
  }
}

export default class {
  constructor() {
    this.$element = document.querySelectorAll('.share-tooltips');
    if (this.$element.length > 0) {
      this.bindClickEvent();
    }
  }

  get options() {
    return {
      facebook: {
        target: '_blank',
        alt: 'แชร์ผ่าน Facebook',
        class: 'btn-share-facebook',
        height: 40,
      },
      messenger: {
        target: '_blank',
        alt: 'แชร์ผ่าน Facebook messenger',
        class: 'btn-share-messenger',
        height: 40,
      },
      line: {
        target: '_blank',
        alt: 'แชร์ผ่าน Line',
        class: 'btn-share-line',
        height: 40,
      },
      email: {
        target: null,
        alt: 'แชร์ผ่าน Email',
        class: 'btn-share-email',
        height: 40,
      },
      link_for_copy: {
        target: null,
        alt: 'Copy url',
        class: 'btn-copy-url',
        height: 40,
      },
    };
  }

  getItemsElement(options, parentNode = null) {
    const keys = Object.keys(this.options);
    let contents = '<div class="tooltip-title">เลือกแชร์ <i class="tk-icon-st-close float-right"></i></div>';
    const imageWrapper = parentNode.parentNode.parentNode;
    keys.forEach((key, index) => {
      let onClick;
      if (index === 0) {
        contents += '<div class="text-left tooltip-buttons">';
      }
      if (key === 'link_for_copy') {
        onClick = '';
      } else if (key === 'email') {
        onClick = `onclick="window.location.href='${options.json[`link_${key}`]}'"`;
      } else {
        onClick = `onclick="window.open('${options.json[`link_${key}`]}')"`;
      }

      contents += `
      <button
        ${this.options[key].target ? `target="${this.options[key].target}"` : ''}
        class="${this.options[key].class} position-relative"
        style=""
        ${onClick}">
          <div class="package-card__share-icon package-card__share-icon--${key}">
            <i class="tk-icon-st-${key}"></i>
          </div>
      </button>`;

      if (index === keys.length - 1) {
        contents += '</div>';
      }
    });

    const $element = document.createElement('div');
    $element.classList.add('js-share-tooltips');
    $element.innerHTML = contents;
    $element.style.top = `-${(keys.length * 40) + 32 + 2}px`; // each elem height + each elem margin + tooltips padding top / bottom + real margin
    if (imageWrapper.className === 'package-card-vertical__image-wrapper') {
      $element.style.width = `${imageWrapper.clientWidth}px`;
    }

    return $element;
  }

  open(e) {
    const elem = e.target.classList.contains('share-tooltips') ? e.target : e.target.closest('.share-tooltips');
    const isAlreadyOpen = document.querySelector('.js-share-tooltips') !== null;
    const isOnToursPage = elem.closest('.summary-details__action-wrapper') !== null;
    if (!elem.classList.contains('active')) {
      if (isAlreadyOpen) this.dispose();
      if (isOnToursPage) summaryDetails.setActive(elem);
      elem.classList.add('active');
      const options = {
        json: safeJsonParse(elem.getAttribute('data-json')),
      };
      if (options.json !== null) {
        elem.appendChild(this.getItemsElement(options, elem.parentNode));
        this.bindCopyTextEvent(options.json.link_for_copy);
      }
    } else if (isAlreadyOpen) {
      this.dispose();
    }
  }

  dispose(e) {
    if (e !== undefined) {
      let elem = e.target;
      elem.classList.remove('active');
      const tooltip = elem.querySelector('.js-share-tooltips');
      const isOnToursPage = elem.closest('.summary-details__action-wrapper') !== null;
      if (isOnToursPage) summaryDetails.setInActive(elem);
      elem = elem.closest('.share-tooltips');
      elem.removeChild(tooltip);
    } else {
      const oldShareTooltips = document.querySelectorAll('.js-share-tooltips');
      if (oldShareTooltips.length > 0) {
        oldShareTooltips.forEach((tooltip) => {
          const isOnToursPage = tooltip.closest('.summary-details__action-wrapper') !== null;
          if (isOnToursPage) summaryDetails.setInActive(tooltip);
          tooltip.closest('.share-tooltips').classList.remove('active');
          tooltip.closest('.share-tooltips').removeChild(tooltip);
        });
      }
    }
  }

  removeBodyClickCheck() {
    document.body.removeEventListener('click', disposeAllIfNotClickAble, true);
    document.body.removeEventListener('touchstart', disposeAllIfNotClickAble, true);
  }

  bindBodyClickCheck() {
    document.body.addEventListener('click', disposeAllIfNotClickAble, true);
    document.body.addEventListener('touchstart', disposeAllIfNotClickAble, true);
  }

  bindCopyTextEvent(url) {
    const copyUrlBtn = document.querySelector('.btn-copy-url');
    copyUrlBtn.addEventListener('click', function (e) {
      e.preventDefault();
      const dummy = document.createElement('input');
      dummy.value = url;
      dummy.setAttribute('readonly', '');
      dummy.style.position = 'absolute';
      dummy.style.left = '-9999px';
      document.body.appendChild(dummy);
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    });
  }

  bindClickEvent() {
    this.$element.forEach(($element) => {
      $element.addEventListener('click', (e) => {
        const elem = e.target;
        const isShareElem = elem.tagName === 'IMG' && elem.closest('a').classList[0].indexOf('btn-share') === 0;
        const isClickAbleElem = elem.closest('.share-tooltips') !== null;
        const isAlreadyOpen = elem.classList.contains('active') && elem.classList.contains('share-tooltips');
        if (isShareElem || isClickAbleElem) {
          if (isClickAbleElem && !isShareElem) e.preventDefault();
          if (isAlreadyOpen) {
            this.dispose(e);
            this.removeBodyClickCheck();
          } else {
            this.open(e);
            this.bindBodyClickCheck();
          }
        }
      });
    });
  }
}
