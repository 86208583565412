'strict mode';

/* eslint class-methods-use-this: 0 */

class OwlCarousel3Way {
  constructor() {
    this.elementsTemp = [];
    this.options = {
      mouseDrag: false,
      touchDrag: true,
      margin: 16,
      stagePadding: 0,
      dots: true,
      items: 1,
      dotsEach: 1,
      slideBy: 1,
      autoHeight: true,
      responsive: {
        376: {
          items: 1.2,
          center: true,
        },
        576: {
          items: 2,
          dotsEach: 2,
          slideBy: 2,
        },
        992: {
          items: 3,
          dotsEach: 3,
          slideBy: 3,
        },
      },
    };
    this.optionsLongHoliday = {
      mouseDrag: true,
      touchDrag: true,
      margin: 16,
      stagePadding: 0,
      dots: true,
      dotsEach: 1,
      items: 1,
      slideBy: 1,
      autoHeight: true,
      responsive: {
        0: { items: 1 },
        375: {
          items: 1.4,
          center: true,
        },
        576: {
          items: 3,
          dotsEach: 3,
          slideBy: 3,
        },
        769: { items: 3 },
      },
    };
    this.optionsTourHighlights= {
      mouseDrag: true,
      touchDrag: true,
      margin: 16,
      stagePadding: 0,
      dots: true,
      dotsEach: 1,
      items: 1,
      slideBy: 1,
      autoHeight: true,
      responsive: {
        0: {
          items: 1.5,
          center: true,
        },
        376: {
          items: 4
        },
      }
    }
  }

  styleSetValue($element, key, value) {
    if (value) {
      $element.css(key, value);
    }
  }

  setup($element, countItems) {
    if (countItems <= 3) {
      this.styleSetValue($element, 'margin', $element.data('margin-no-arrow'));
    }
  }

  watchNavigation($element) {
    if($element.find('.owl-item').length === 0) { return }
    const $firstItem = $element.find('.owl-item:first-child');
    const $lastItem = $element.find('.owl-item:last-child');
    const $navLeft = $element.find('.owl-box-left');
    const $navRight = $element.find('.owl-box-right');
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.attributeName === 'class') {
          if ($firstItem.hasClass('active')) {
            $navLeft.addClass('owl-disabled-nav');
            $navRight.removeClass('owl-disabled-nav');
          } else if ($lastItem.hasClass('active')) {
            $navRight.addClass('owl-disabled-nav');
            $navLeft.removeClass('owl-disabled-nav');
          } else {
            $navRight.removeClass('owl-disabled-nav');
            $navLeft.removeClass('owl-disabled-nav');
          }
        }
      });
    });
    observer.observe($firstItem[0], { attributes: true });
    observer.observe($lastItem[0], { attributes: true });
  }

  start() {
    const $owl = $('.owl-carousel-3-way');
    const $vm = this;
    if ($owl.length > 0) {
      $owl.each(function (index, element) {
        const $element = $(element);
        const $items = $element.html();
        const countItems = $element.children().length;
        if(countItems === 0) { return; }
        $vm.setup($element, countItems);
        $vm.elementsTemp.push({ $element, $items });
        $vm.replaceContent($element, $items, countItems);
        const $carouselItem = $element.find('.owl-carousel');
        const $eleBoxLeft = $element.find('.owl-box-left');
        const $eleBoxRight = $element.find('.owl-box-right');
        $vm.create({
          $element,
          $carouselItem,
          index,
          $eleBoxLeft,
          $eleBoxRight,
        });
        $element.find('.owl-nav.disabled').remove();
        $carouselItem.owlCarousel('onResize');
        $carouselItem.trigger('refresh.owl.carousel');
        $carouselItem.trigger('next.owl.carousel');
        $carouselItem.trigger('prev.owl.carousel');
        $carouselItem.parents('.owl-carousel-3-way').addClass('loaded');
        index += 1;
        $vm.watchNavigation($element);
      });
    }
  }

  replaceContent($element, $items, countItems) {
    const boxLeft = countItems > 3 ? '<div class="owl-box-left owl-disabled-nav"></div>' : '';
    const boxRight = countItems > 3 ? '<div class="owl-box-right"></div>' : '';
    const longHolidayClass = $element.hasClass('long-holidays-carousel') ? 'long-holidays' : '';
    const TourHighlightClass = $element.hasClass('tour-highlight-carousel') ? 'tour-highlights' : '';

    $element.html(`
      ${boxLeft}
      <div class="owl-box-center">
        <div class="curations-row owl-carousel owl-theme owl-height ${longHolidayClass} ${TourHighlightClass}">
          ${$items}
        </div>
      </div>
      ${boxRight}
    `);
  }

  create(options) {
    if (options.$carouselItem.length > 0) {
      let optionsObject;
      if(options.$carouselItem.hasClass('long-holidays')) {
        optionsObject = this.optionsLongHoliday
      } else if(options.$carouselItem.hasClass('tour-highlights')){
        optionsObject = this.optionsTourHighlights
      } else {
        optionsObject = this.options;
      }
      options.$carouselItem.owlCarousel(optionsObject);
      this.insertOwlCarouselNav(options);
      this.bindNavEvents(
        options.$carouselItem,
        options.$eleBoxLeft.find('.btn-nav-prev-item'),
        options.$eleBoxRight.find('.btn-nav-next-item'),
      );
    }
  }

  insertOwlCarouselNav(options) {
    const owlDots = options.$carouselItem.find('.owl-dots');
    const prevItemClass = `btn-nav-prev-item-${options.index}`;
    const nextItemClass = `btn-nav-next-item-${options.index}`;
    const $prevItemElem = options.$carouselItem.find(`.${prevItemClass}`);
    const $nextItemElem = options.$carouselItem.find(`.${nextItemClass}`);
    const isExisted = $prevItemElem.length && $nextItemElem.length;
    const isDotEnable = !owlDots.hasClass('disabled');

    if (isDotEnable) {
      const btnNavPrevItem = `
        <div class="btn-nav-prev-item group-shadow rounded-circle ${prevItemClass}">
          <i class="far fa-chevron-left"></i>
        </div>`;
      const btnNavNextItem = `
        <div class="btn-nav-next-item group-shadow rounded-circle ${nextItemClass}">
          <i class="far fa-chevron-right"></i>
        </div>`;

      if (!isExisted) {
        $(options.$eleBoxLeft).html(btnNavPrevItem);
        $(options.$eleBoxRight).html(btnNavNextItem);
      }
    } else {
      $prevItemElem.remove();
      $nextItemElem.remove();
    }
  }

  bindNavEvents($owlCarousel, $prev, $next) {
    $prev.on('click', () => { $owlCarousel.trigger('prev.owl.carousel'); });
    $next.on('click', () => { $owlCarousel.trigger('next.owl.carousel'); });
  }

  destroy() {
    const $owl = $('.owl-carousel-3-way');
    if ($owl.length > 0) {
      $owl.each(function () {
        const $carouselItem = $(this).find('.owl-carousel');
        $($carouselItem).owlCarousel('destroy');
        $($carouselItem).off('resized.owl.carousel');
      });
    }
    if (this.elementsTemp.length > 0) {
      this.elementsTemp.forEach(function (v) {
        v.$element.html(v.$items);
      });
    }
  }
}

const App = new OwlCarousel3Way();

export default {
  turbolinksBeforeCache() {
    App.destroy();
  },
  turbolinksLoad() {
    App.start();
  },
};
